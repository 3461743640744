/* tslint:disable */
/* eslint-disable */
/**
 * Portal Telemedicina API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminExamViewModel } from '../models';
// @ts-ignore
import { AdminPostObservationViewModel } from '../models';
// @ts-ignore
import { AdminPullViewModel } from '../models';
// @ts-ignore
import { AilaEvaluateExamComparisonViewModel } from '../models';
// @ts-ignore
import { AilaResultComparisonReportsViewModel } from '../models';
// @ts-ignore
import { CancelDuplicatesRequestViewModel } from '../models';
// @ts-ignore
import { CdpDivergenceResolveViewModel } from '../models';
// @ts-ignore
import { DuplicatesResponseViewModel } from '../models';
// @ts-ignore
import { ExamAIStudyViewModel } from '../models';
// @ts-ignore
import { ExamCountersViewModel } from '../models';
// @ts-ignore
import { ExamDetailsViewModel } from '../models';
// @ts-ignore
import { ExamInformationViewModel } from '../models';
// @ts-ignore
import { ExamPrioritasPopulateQueueViewModel } from '../models';
// @ts-ignore
import { ExamQueueItemViewModel } from '../models';
// @ts-ignore
import { ExamRepetitionReasonViewModel } from '../models';
// @ts-ignore
import { ExamReportDocumentRepleacedViewModel } from '../models';
// @ts-ignore
import { ExamResultViewModel } from '../models';
// @ts-ignore
import { ExamSpecialityChangeViewModel } from '../models';
// @ts-ignore
import { ExamStatusHistoryExportViewModel } from '../models';
// @ts-ignore
import { ExamStatusViewModel } from '../models';
// @ts-ignore
import { ExamUpdateViewModel } from '../models';
// @ts-ignore
import { ExamViewModel } from '../models';
// @ts-ignore
import { InlineObject10 } from '../models';
// @ts-ignore
import { InlineObject11 } from '../models';
// @ts-ignore
import { InlineObject12 } from '../models';
// @ts-ignore
import { InlineObject13 } from '../models';
// @ts-ignore
import { InlineObject14 } from '../models';
// @ts-ignore
import { InlineObject15 } from '../models';
// @ts-ignore
import { InlineObject16 } from '../models';
// @ts-ignore
import { InlineObject2 } from '../models';
// @ts-ignore
import { InlineObject20 } from '../models';
// @ts-ignore
import { InlineObject3 } from '../models';
// @ts-ignore
import { InlineObject4 } from '../models';
// @ts-ignore
import { InlineObject5 } from '../models';
// @ts-ignore
import { InlineObject6 } from '../models';
// @ts-ignore
import { InlineObject7 } from '../models';
// @ts-ignore
import { InlineObject8 } from '../models';
// @ts-ignore
import { Notification } from '../models';
// @ts-ignore
import { PostDivergenceViewModel } from '../models';
// @ts-ignore
import { ReleaseReportViewModel } from '../models';
// @ts-ignore
import { ReportDivergenceViewModel } from '../models';
// @ts-ignore
import { SimpleListViewModel } from '../models';
// @ts-ignore
import { SpecialityViewModel } from '../models';
// @ts-ignore
import { StatusExamsEnum } from '../models';
/**
 * ExamApi - axios parameter creator
 * @export
 */
export const ExamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [xAuth] 
         * @param {PostDivergenceViewModel} [postDivergenceViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamConfirmDivergencePost: async (xAuth?: string, postDivergenceViewModel?: PostDivergenceViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Administrator/Exam/ConfirmDivergence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postDivergenceViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamDivergenceGet: async (examId?: number, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Administrator/Exam/Divergence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (examId !== undefined) {
                localVarQueryParameter['examId'] = examId;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {CdpDivergenceResolveViewModel} [cdpDivergenceResolveViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamDivergenceResolvePost: async (xAuth?: string, cdpDivergenceResolveViewModel?: CdpDivergenceResolveViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Administrator/Exam/DivergenceResolve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cdpDivergenceResolveViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {PostDivergenceViewModel} [postDivergenceViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamDivergenceUpdatePost: async (xAuth?: string, postDivergenceViewModel?: PostDivergenceViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Administrator/Exam/DivergenceUpdate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postDivergenceViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamPatientAgeFromExamIdGet: async (examId?: number, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Administrator/Exam/PatientAgeFromExamId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (examId !== undefined) {
                localVarQueryParameter['examId'] = examId;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamsAIStudyGet: async (examId?: number, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Administrator/Exams/AIStudy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (examId !== undefined) {
                localVarQueryParameter['examId'] = examId;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {ExamAIStudyViewModel} [examAIStudyViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamsAIStudyPost: async (xAuth?: string, examAIStudyViewModel?: ExamAIStudyViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Administrator/Exams/AIStudy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(examAIStudyViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamsComparisonGet: async (examId?: number, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Administrator/Exams/Comparison`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (examId !== undefined) {
                localVarQueryParameter['examId'] = examId;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {AilaEvaluateExamComparisonViewModel} [ailaEvaluateExamComparisonViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamsEvaluateAilaResultPut: async (xAuth?: string, ailaEvaluateExamComparisonViewModel?: AilaEvaluateExamComparisonViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Administrator/Exams/EvaluateAilaResult`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ailaEvaluateExamComparisonViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [patientName] 
         * @param {number} [categoryId] 
         * @param {number} [specialityId] 
         * @param {StatusExamsEnum} [statusId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [examStartDate] 
         * @param {string} [examEndDate] 
         * @param {string} [orderColumn] 
         * @param {string} [orderBy] 
         * @param {string} [lastReportStartDate] 
         * @param {string} [lastReportEndDate] 
         * @param {boolean} [printed] 
         * @param {Array<number>} [sourceId] 
         * @param {number} [physicianId] 
         * @param {boolean} [internalSystem] 
         * @param {boolean} [urgencyAlert] 
         * @param {boolean} [divergence] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamsGet: async (examId?: number, patientName?: string, categoryId?: number, specialityId?: number, statusId?: StatusExamsEnum, page?: number, pageSize?: number, examStartDate?: string, examEndDate?: string, orderColumn?: string, orderBy?: string, lastReportStartDate?: string, lastReportEndDate?: string, printed?: boolean, sourceId?: Array<number>, physicianId?: number, internalSystem?: boolean, urgencyAlert?: boolean, divergence?: boolean, notifications?: Array<Notification>, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Administrator/Exams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (examId !== undefined) {
                localVarQueryParameter['ExamId'] = examId;
            }

            if (patientName !== undefined) {
                localVarQueryParameter['PatientName'] = patientName;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['CategoryId'] = categoryId;
            }

            if (specialityId !== undefined) {
                localVarQueryParameter['SpecialityId'] = specialityId;
            }

            if (statusId !== undefined) {
                localVarQueryParameter['StatusId'] = statusId;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (examStartDate !== undefined) {
                localVarQueryParameter['ExamStartDate'] = (examStartDate as any instanceof Date) ?
                    (examStartDate as any).toISOString() :
                    examStartDate;
            }

            if (examEndDate !== undefined) {
                localVarQueryParameter['ExamEndDate'] = (examEndDate as any instanceof Date) ?
                    (examEndDate as any).toISOString() :
                    examEndDate;
            }

            if (orderColumn !== undefined) {
                localVarQueryParameter['OrderColumn'] = orderColumn;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (lastReportStartDate !== undefined) {
                localVarQueryParameter['LastReportStartDate'] = (lastReportStartDate as any instanceof Date) ?
                    (lastReportStartDate as any).toISOString() :
                    lastReportStartDate;
            }

            if (lastReportEndDate !== undefined) {
                localVarQueryParameter['LastReportEndDate'] = (lastReportEndDate as any instanceof Date) ?
                    (lastReportEndDate as any).toISOString() :
                    lastReportEndDate;
            }

            if (printed !== undefined) {
                localVarQueryParameter['Printed'] = printed;
            }

            if (sourceId) {
                localVarQueryParameter['SourceId'] = sourceId;
            }

            if (physicianId !== undefined) {
                localVarQueryParameter['PhysicianId'] = physicianId;
            }

            if (internalSystem !== undefined) {
                localVarQueryParameter['InternalSystem'] = internalSystem;
            }

            if (urgencyAlert !== undefined) {
                localVarQueryParameter['UrgencyAlert'] = urgencyAlert;
            }

            if (divergence !== undefined) {
                localVarQueryParameter['Divergence'] = divergence;
            }

            if (notifications) {
                localVarQueryParameter['Notifications'] = notifications;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {AdminPostObservationViewModel} [adminPostObservationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamsObservationPost: async (xAuth?: string, adminPostObservationViewModel?: AdminPostObservationViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Administrator/Exams/Observation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminPostObservationViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {AdminPullViewModel} [adminPullViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamsPullPost: async (xAuth?: string, adminPullViewModel?: AdminPullViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Administrator/Exams/Pull`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminPullViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {ReleaseReportViewModel} [releaseReportViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamsReleaseReportPost: async (xAuth?: string, releaseReportViewModel?: ReleaseReportViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Administrator/Exams/ReleaseReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(releaseReportViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamsRetificationReasonsGet: async (xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Administrator/Exams/RetificationReasons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analystExamsQueueHasNextGet: async (xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Analyst/Exams/Queue/HasNext`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAtenaAdminUserGet: async (page?: number, pageSize?: number, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/atena/admin/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject8} inlineObject8 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAtenaAdminUserPut: async (inlineObject8: InlineObject8, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject8' is not null or undefined
            assertParamExists('apiAtenaAdminUserPut', 'inlineObject8', inlineObject8)
            const localVarPath = `/api/atena/admin/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject8, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject14} inlineObject14 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamAssignExamToPhysicianPost: async (inlineObject14: InlineObject14, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject14' is not null or undefined
            assertParamExists('apiCoreAdministratorExamAssignExamToPhysicianPost', 'inlineObject14', inlineObject14)
            const localVarPath = `/api/core/Administrator/Exam/AssignExamToPhysician`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject14, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject11} inlineObject11 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamReplaceReportDocumentPost: async (inlineObject11: InlineObject11, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject11' is not null or undefined
            assertParamExists('apiCoreAdministratorExamReplaceReportDocumentPost', 'inlineObject11', inlineObject11)
            const localVarPath = `/api/core/Administrator/Exam/ReplaceReportDocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject11, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {number} [examFileId] 
         * @param {string} [md5] 
         * @param {string} [extension] 
         * @param {string} [fileType] 
         * @param {string} [fileName] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamSignedUrlGet: async (examId?: number, examFileId?: number, md5?: string, extension?: string, fileType?: string, fileName?: string, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/core/Administrator/Exam/SignedUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (examId !== undefined) {
                localVarQueryParameter['examId'] = examId;
            }

            if (examFileId !== undefined) {
                localVarQueryParameter['examFileId'] = examFileId;
            }

            if (md5 !== undefined) {
                localVarQueryParameter['Md5'] = md5;
            }

            if (extension !== undefined) {
                localVarQueryParameter['Extension'] = extension;
            }

            if (fileType !== undefined) {
                localVarQueryParameter['FileType'] = fileType;
            }

            if (fileName !== undefined) {
                localVarQueryParameter['FileName'] = fileName;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject15} inlineObject15 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamsCancelPut: async (inlineObject15: InlineObject15, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject15' is not null or undefined
            assertParamExists('apiCoreAdministratorExamsCancelPut', 'inlineObject15', inlineObject15)
            const localVarPath = `/api/core/Administrator/Exams/Cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject15, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject13} inlineObject13 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamsChangePhysicianPut: async (inlineObject13: InlineObject13, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject13' is not null or undefined
            assertParamExists('apiCoreAdministratorExamsChangePhysicianPut', 'inlineObject13', inlineObject13)
            const localVarPath = `/api/core/Administrator/Exams/ChangePhysician`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject13, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamsDetailsGet: async (examId?: number, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/core/Administrator/Exams/Details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (examId !== undefined) {
                localVarQueryParameter['examId'] = examId;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [examFileId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamsFileGet: async (examFileId?: number, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/core/Administrator/Exams/File`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (examFileId !== undefined) {
                localVarQueryParameter['examFileId'] = examFileId;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [physicianName] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamsPhysiciansForExamGet: async (examId?: number, physicianName?: string, page?: number, pageSize?: number, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/core/Administrator/Exams/PhysiciansForExam`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (examId !== undefined) {
                localVarQueryParameter['ExamId'] = examId;
            }

            if (physicianName !== undefined) {
                localVarQueryParameter['PhysicianName'] = physicianName;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject12} inlineObject12 
         * @param {number} [examId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamsRetificationPost: async (inlineObject12: InlineObject12, examId?: number, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject12' is not null or undefined
            assertParamExists('apiCoreAdministratorExamsRetificationPost', 'inlineObject12', inlineObject12)
            const localVarPath = `/api/core/Administrator/Exams/Retification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (examId !== undefined) {
                localVarQueryParameter['ExamId'] = examId;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject12, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {number} [examFileId] 
         * @param {string} [md5] 
         * @param {string} [extension] 
         * @param {string} [fileType] 
         * @param {string} [fileName] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamsSetSuccessUploadStatusPut: async (examId?: number, examFileId?: number, md5?: string, extension?: string, fileType?: string, fileName?: string, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/core/Administrator/Exams/SetSuccessUploadStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (examId !== undefined) {
                localVarQueryParameter['examId'] = examId;
            }

            if (examFileId !== undefined) {
                localVarQueryParameter['examFileId'] = examFileId;
            }

            if (md5 !== undefined) {
                localVarQueryParameter['Md5'] = md5;
            }

            if (extension !== undefined) {
                localVarQueryParameter['Extension'] = extension;
            }

            if (fileType !== undefined) {
                localVarQueryParameter['FileType'] = fileType;
            }

            if (fileName !== undefined) {
                localVarQueryParameter['FileName'] = fileName;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject16} inlineObject16 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamAssociatePost: async (inlineObject16: InlineObject16, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject16' is not null or undefined
            assertParamExists('apiCoreExamAssociatePost', 'inlineObject16', inlineObject16)
            const localVarPath = `/api/core/Exam/Associate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject16, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} examId 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamGetAssociationCandidatesExamIdGet: async (examId: number, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'examId' is not null or undefined
            assertParamExists('apiCoreExamGetAssociationCandidatesExamIdGet', 'examId', examId)
            const localVarPath = `/api/core/Exam/GetAssociationCandidates/{examId}`
                .replace(`{${"examId"}}`, encodeURIComponent(String(examId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [specialityId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceCategoryGet: async (specialityId?: number, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/core/ExamSource/Category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (specialityId !== undefined) {
                localVarQueryParameter['specialityId'] = specialityId;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [sourceId] 
         * @param {string} [cpf] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceExamsByDocumentGet: async (sourceId?: number, cpf?: string, page?: number, pageSize?: number, notifications?: Array<Notification>, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/core/ExamSource/ExamsByDocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sourceId !== undefined) {
                localVarQueryParameter['SourceId'] = sourceId;
            }

            if (cpf !== undefined) {
                localVarQueryParameter['Cpf'] = cpf;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (notifications) {
                localVarQueryParameter['Notifications'] = notifications;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} examId 
         * @param {InlineObject3} inlineObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceExamsExamIdPut: async (examId: number, inlineObject3: InlineObject3, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'examId' is not null or undefined
            assertParamExists('apiCoreExamSourceExamsExamIdPut', 'examId', examId)
            // verify required parameter 'inlineObject3' is not null or undefined
            assertParamExists('apiCoreExamSourceExamsExamIdPut', 'inlineObject3', inlineObject3)
            const localVarPath = `/api/core/ExamSource/Exams/{examId}`
                .replace(`{${"examId"}}`, encodeURIComponent(String(examId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject3, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} examId 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceExamsExamIdReportGet: async (examId: number, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'examId' is not null or undefined
            assertParamExists('apiCoreExamSourceExamsExamIdReportGet', 'examId', examId)
            const localVarPath = `/api/core/ExamSource/Exams/{examId}/Report`
                .replace(`{${"examId"}}`, encodeURIComponent(String(examId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [sourceId] 
         * @param {number} [examId] 
         * @param {string} [patientName] 
         * @param {number} [categoryId] 
         * @param {number} [specialityId] 
         * @param {number} [statusId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [examStartDate] 
         * @param {string} [examEndDate] 
         * @param {string} [orderColumn] 
         * @param {string} [orderBy] 
         * @param {boolean} [printed] 
         * @param {boolean} [urgent] 
         * @param {boolean} [urgencyAlert] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [cpf] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceExamsGet: async (sourceId?: number, examId?: number, patientName?: string, categoryId?: number, specialityId?: number, statusId?: number, page?: number, pageSize?: number, examStartDate?: string, examEndDate?: string, orderColumn?: string, orderBy?: string, printed?: boolean, urgent?: boolean, urgencyAlert?: boolean, notifications?: Array<Notification>, cpf?: string, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/core/ExamSource/Exams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sourceId !== undefined) {
                localVarQueryParameter['SourceId'] = sourceId;
            }

            if (examId !== undefined) {
                localVarQueryParameter['ExamId'] = examId;
            }

            if (patientName !== undefined) {
                localVarQueryParameter['PatientName'] = patientName;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['CategoryId'] = categoryId;
            }

            if (specialityId !== undefined) {
                localVarQueryParameter['SpecialityId'] = specialityId;
            }

            if (statusId !== undefined) {
                localVarQueryParameter['StatusId'] = statusId;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (examStartDate !== undefined) {
                localVarQueryParameter['ExamStartDate'] = (examStartDate as any instanceof Date) ?
                    (examStartDate as any).toISOString() :
                    examStartDate;
            }

            if (examEndDate !== undefined) {
                localVarQueryParameter['ExamEndDate'] = (examEndDate as any instanceof Date) ?
                    (examEndDate as any).toISOString() :
                    examEndDate;
            }

            if (orderColumn !== undefined) {
                localVarQueryParameter['OrderColumn'] = orderColumn;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (printed !== undefined) {
                localVarQueryParameter['Printed'] = printed;
            }

            if (urgent !== undefined) {
                localVarQueryParameter['Urgent'] = urgent;
            }

            if (urgencyAlert !== undefined) {
                localVarQueryParameter['UrgencyAlert'] = urgencyAlert;
            }

            if (notifications) {
                localVarQueryParameter['Notifications'] = notifications;
            }

            if (cpf !== undefined) {
                localVarQueryParameter['Cpf'] = cpf;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject7} inlineObject7 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceExamsObservationPost: async (inlineObject7: InlineObject7, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject7' is not null or undefined
            assertParamExists('apiCoreExamSourceExamsObservationPost', 'inlineObject7', inlineObject7)
            const localVarPath = `/api/core/ExamSource/Exams/Observation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject7, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [examsId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceExamsReportsGet: async (examsId?: Array<number>, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/core/ExamSource/Exams/Reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (examsId) {
                localVarQueryParameter['examsId'] = examsId;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject2} inlineObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceExamsSetUrgentExamPut: async (inlineObject2: InlineObject2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject2' is not null or undefined
            assertParamExists('apiCoreExamSourceExamsSetUrgentExamPut', 'inlineObject2', inlineObject2)
            const localVarPath = `/api/core/ExamSource/Exams/SetUrgentExam`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceExamsStatusGet: async (xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/core/ExamSource/Exams/Status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamsInformationGet: async (examId?: number, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/core/Exams/Information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (examId !== undefined) {
                localVarQueryParameter['examId'] = examId;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject10} inlineObject10 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamsInformationPost: async (inlineObject10: InlineObject10, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject10' is not null or undefined
            assertParamExists('apiCoreExamsInformationPost', 'inlineObject10', inlineObject10)
            const localVarPath = `/api/core/Exams/Information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject10, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject5} inlineObject5 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamsSendReviewPost: async (inlineObject5: InlineObject5, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject5' is not null or undefined
            assertParamExists('apiCoreExamsSendReviewPost', 'inlineObject5', inlineObject5)
            const localVarPath = `/api/core/Exams/SendReview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject5, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject4} inlineObject4 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCorePhysicianExamsLaudarInsertMedicalReportPost: async (inlineObject4: InlineObject4, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject4' is not null or undefined
            assertParamExists('apiCorePhysicianExamsLaudarInsertMedicalReportPost', 'inlineObject4', inlineObject4)
            const localVarPath = `/api/core/Physician/Exams/Laudar/InsertMedicalReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject4, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCorePhysicianExamsQueueGetItemGet: async (xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/core/Physician/Exams/Queue/GetItem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCorePhysicianExamsQueueHasNextGet: async (xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/core/Physician/Exams/Queue/HasNext`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [specialityId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCorePhysicianExamsReasonsToRepeatExamBySpecialityIdGet: async (specialityId?: number, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/core/Physician/Exams/ReasonsToRepeatExamBySpecialityId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (specialityId !== undefined) {
                localVarQueryParameter['specialityId'] = specialityId;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject6} inlineObject6 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCorePhysicianExamsRepeatExamPut: async (inlineObject6: InlineObject6, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject6' is not null or undefined
            assertParamExists('apiCorePhysicianExamsRepeatExamPut', 'inlineObject6', inlineObject6)
            const localVarPath = `/api/core/Physician/Exams/RepeatExam`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject6, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {ExamUpdateViewModel} [examUpdateViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCorePhysicianExamsUnassignExamMedicPut: async (xAuth?: string, examUpdateViewModel?: ExamUpdateViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/core/Physician/Exams/UnassignExamMedic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(examUpdateViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [sourceId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreUrgencyAlertGetAllNoCheckedAlertsGet: async (sourceId?: number, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/core/UrgencyAlert/GetAllNoCheckedAlerts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sourceId !== undefined) {
                localVarQueryParameter['sourceId'] = sourceId;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreUrgencyAlertGetUrgencyAlertsForAdminGet: async (examId?: number, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/core/UrgencyAlert/GetUrgencyAlertsForAdmin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (examId !== undefined) {
                localVarQueryParameter['ExamId'] = examId;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {InlineObject20} [inlineObject20] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreUrgencyAlertPostIKnowPut: async (xAuth?: string, inlineObject20?: InlineObject20, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/core/UrgencyAlert/PostIKnow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject20, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [sourceId] 
         * @param {number} [examId] 
         * @param {string} [patientName] 
         * @param {number} [categoryId] 
         * @param {number} [specialityId] 
         * @param {Array<number>} [statusId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [examStartDate] 
         * @param {string} [examEndDate] 
         * @param {string} [orderColumn] 
         * @param {string} [orderBy] 
         * @param {boolean} [printed] 
         * @param {boolean} [urgent] 
         * @param {boolean} [urgencyAlert] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPtmsyncPreOrderGet: async (sourceId?: number, examId?: number, patientName?: string, categoryId?: number, specialityId?: number, statusId?: Array<number>, page?: number, pageSize?: number, examStartDate?: string, examEndDate?: string, orderColumn?: string, orderBy?: string, printed?: boolean, urgent?: boolean, urgencyAlert?: boolean, notifications?: Array<Notification>, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ptmsync/PreOrder/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sourceId !== undefined) {
                localVarQueryParameter['SourceId'] = sourceId;
            }

            if (examId !== undefined) {
                localVarQueryParameter['ExamId'] = examId;
            }

            if (patientName !== undefined) {
                localVarQueryParameter['PatientName'] = patientName;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['CategoryId'] = categoryId;
            }

            if (specialityId !== undefined) {
                localVarQueryParameter['SpecialityId'] = specialityId;
            }

            if (statusId) {
                localVarQueryParameter['StatusId'] = statusId;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (examStartDate !== undefined) {
                localVarQueryParameter['ExamStartDate'] = (examStartDate as any instanceof Date) ?
                    (examStartDate as any).toISOString() :
                    examStartDate;
            }

            if (examEndDate !== undefined) {
                localVarQueryParameter['ExamEndDate'] = (examEndDate as any instanceof Date) ?
                    (examEndDate as any).toISOString() :
                    examEndDate;
            }

            if (orderColumn !== undefined) {
                localVarQueryParameter['OrderColumn'] = orderColumn;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (printed !== undefined) {
                localVarQueryParameter['Printed'] = printed;
            }

            if (urgent !== undefined) {
                localVarQueryParameter['Urgent'] = urgent;
            }

            if (urgencyAlert !== undefined) {
                localVarQueryParameter['UrgencyAlert'] = urgencyAlert;
            }

            if (notifications) {
                localVarQueryParameter['Notifications'] = notifications;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {CancelDuplicatesRequestViewModel} [cancelDuplicatesRequestViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examCancelDuplicatesPost: async (xAuth?: string, cancelDuplicatesRequestViewModel?: CancelDuplicatesRequestViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Exam/CancelDuplicates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelDuplicatesRequestViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examDuplicatesGet: async (name?: string, month?: number, year?: number, notifications?: Array<Notification>, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Exam/Duplicates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (month !== undefined) {
                localVarQueryParameter['Month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (notifications) {
                localVarQueryParameter['Notifications'] = notifications;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examRepeatTimeoutPost: async (xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Exam/RepeatTimeout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [category] 
         * @param {number} [status] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSimpleListGet: async (category?: number, status?: number, page?: number, pageSize?: number, notifications?: Array<Notification>, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Exam/SimpleList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (category !== undefined) {
                localVarQueryParameter['Category'] = category;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (notifications) {
                localVarQueryParameter['Notifications'] = notifications;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {ExamUpdateViewModel} [examUpdateViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSourceExamsSetUrgentExamPut: async (xAuth?: string, examUpdateViewModel?: ExamUpdateViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ExamSource/Exams/SetUrgentExam`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(examUpdateViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examUpdatedExamsGet: async (notifications?: Array<Notification>, startDate?: string, endDate?: string, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Exam/UpdatedExams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (notifications) {
                localVarQueryParameter['Notifications'] = notifications;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['StartDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsCountersGet: async (startDate?: string, endDate?: string, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Exams/Counters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsQueuePopulateGet: async (xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Exams/Queue/Populate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {ExamSpecialityChangeViewModel} [examSpecialityChangeViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsSpecialityPut: async (xAuth?: string, examSpecialityChangeViewModel?: ExamSpecialityChangeViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Exams/Speciality`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(examSpecialityChangeViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} examId 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        physicianExamsExamIdExamFileGet: async (examId: number, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'examId' is not null or undefined
            assertParamExists('physicianExamsExamIdExamFileGet', 'examId', examId)
            const localVarPath = `/Physician/Exams/{examId}/ExamFile`
                .replace(`{${"examId"}}`, encodeURIComponent(String(examId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        physicianExamsReportedGet: async (xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Physician/Exams/Reported`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {ExamUpdateViewModel} [examUpdateViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        physicianExamsUpdateDateLasUpdatetExamPut: async (xAuth?: string, examUpdateViewModel?: ExamUpdateViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Physician/Exams/UpdateDateLasUpdatetExam`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(examUpdateViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExamApi - functional programming interface
 * @export
 */
export const ExamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExamApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [xAuth] 
         * @param {PostDivergenceViewModel} [postDivergenceViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorExamConfirmDivergencePost(xAuth?: string, postDivergenceViewModel?: PostDivergenceViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorExamConfirmDivergencePost(xAuth, postDivergenceViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorExamDivergenceGet(examId?: number, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportDivergenceViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorExamDivergenceGet(examId, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {CdpDivergenceResolveViewModel} [cdpDivergenceResolveViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorExamDivergenceResolvePost(xAuth?: string, cdpDivergenceResolveViewModel?: CdpDivergenceResolveViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorExamDivergenceResolvePost(xAuth, cdpDivergenceResolveViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {PostDivergenceViewModel} [postDivergenceViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorExamDivergenceUpdatePost(xAuth?: string, postDivergenceViewModel?: PostDivergenceViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorExamDivergenceUpdatePost(xAuth, postDivergenceViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorExamPatientAgeFromExamIdGet(examId?: number, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorExamPatientAgeFromExamIdGet(examId, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorExamsAIStudyGet(examId?: number, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExamAIStudyViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorExamsAIStudyGet(examId, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {ExamAIStudyViewModel} [examAIStudyViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorExamsAIStudyPost(xAuth?: string, examAIStudyViewModel?: ExamAIStudyViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorExamsAIStudyPost(xAuth, examAIStudyViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorExamsComparisonGet(examId?: number, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AilaResultComparisonReportsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorExamsComparisonGet(examId, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {AilaEvaluateExamComparisonViewModel} [ailaEvaluateExamComparisonViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorExamsEvaluateAilaResultPut(xAuth?: string, ailaEvaluateExamComparisonViewModel?: AilaEvaluateExamComparisonViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorExamsEvaluateAilaResultPut(xAuth, ailaEvaluateExamComparisonViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [patientName] 
         * @param {number} [categoryId] 
         * @param {number} [specialityId] 
         * @param {StatusExamsEnum} [statusId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [examStartDate] 
         * @param {string} [examEndDate] 
         * @param {string} [orderColumn] 
         * @param {string} [orderBy] 
         * @param {string} [lastReportStartDate] 
         * @param {string} [lastReportEndDate] 
         * @param {boolean} [printed] 
         * @param {Array<number>} [sourceId] 
         * @param {number} [physicianId] 
         * @param {boolean} [internalSystem] 
         * @param {boolean} [urgencyAlert] 
         * @param {boolean} [divergence] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorExamsGet(examId?: number, patientName?: string, categoryId?: number, specialityId?: number, statusId?: StatusExamsEnum, page?: number, pageSize?: number, examStartDate?: string, examEndDate?: string, orderColumn?: string, orderBy?: string, lastReportStartDate?: string, lastReportEndDate?: string, printed?: boolean, sourceId?: Array<number>, physicianId?: number, internalSystem?: boolean, urgencyAlert?: boolean, divergence?: boolean, notifications?: Array<Notification>, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminExamViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorExamsGet(examId, patientName, categoryId, specialityId, statusId, page, pageSize, examStartDate, examEndDate, orderColumn, orderBy, lastReportStartDate, lastReportEndDate, printed, sourceId, physicianId, internalSystem, urgencyAlert, divergence, notifications, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {AdminPostObservationViewModel} [adminPostObservationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorExamsObservationPost(xAuth?: string, adminPostObservationViewModel?: AdminPostObservationViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorExamsObservationPost(xAuth, adminPostObservationViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {AdminPullViewModel} [adminPullViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorExamsPullPost(xAuth?: string, adminPullViewModel?: AdminPullViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorExamsPullPost(xAuth, adminPullViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {ReleaseReportViewModel} [releaseReportViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorExamsReleaseReportPost(xAuth?: string, releaseReportViewModel?: ReleaseReportViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorExamsReleaseReportPost(xAuth, releaseReportViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorExamsRetificationReasonsGet(xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExamCountersViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorExamsRetificationReasonsGet(xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analystExamsQueueHasNextGet(xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analystExamsQueueHasNextGet(xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAtenaAdminUserGet(page?: number, pageSize?: number, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAtenaAdminUserGet(page, pageSize, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject8} inlineObject8 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAtenaAdminUserPut(inlineObject8: InlineObject8, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAtenaAdminUserPut(inlineObject8, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject14} inlineObject14 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreAdministratorExamAssignExamToPhysicianPost(inlineObject14: InlineObject14, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreAdministratorExamAssignExamToPhysicianPost(inlineObject14, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject11} inlineObject11 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreAdministratorExamReplaceReportDocumentPost(inlineObject11: InlineObject11, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExamReportDocumentRepleacedViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreAdministratorExamReplaceReportDocumentPost(inlineObject11, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {number} [examFileId] 
         * @param {string} [md5] 
         * @param {string} [extension] 
         * @param {string} [fileType] 
         * @param {string} [fileName] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreAdministratorExamSignedUrlGet(examId?: number, examFileId?: number, md5?: string, extension?: string, fileType?: string, fileName?: string, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreAdministratorExamSignedUrlGet(examId, examFileId, md5, extension, fileType, fileName, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject15} inlineObject15 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreAdministratorExamsCancelPut(inlineObject15: InlineObject15, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreAdministratorExamsCancelPut(inlineObject15, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject13} inlineObject13 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreAdministratorExamsChangePhysicianPut(inlineObject13: InlineObject13, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreAdministratorExamsChangePhysicianPut(inlineObject13, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreAdministratorExamsDetailsGet(examId?: number, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExamDetailsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreAdministratorExamsDetailsGet(examId, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [examFileId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreAdministratorExamsFileGet(examFileId?: number, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreAdministratorExamsFileGet(examFileId, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [physicianName] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreAdministratorExamsPhysiciansForExamGet(examId?: number, physicianName?: string, page?: number, pageSize?: number, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreAdministratorExamsPhysiciansForExamGet(examId, physicianName, page, pageSize, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject12} inlineObject12 
         * @param {number} [examId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreAdministratorExamsRetificationPost(inlineObject12: InlineObject12, examId?: number, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreAdministratorExamsRetificationPost(inlineObject12, examId, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {number} [examFileId] 
         * @param {string} [md5] 
         * @param {string} [extension] 
         * @param {string} [fileType] 
         * @param {string} [fileName] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreAdministratorExamsSetSuccessUploadStatusPut(examId?: number, examFileId?: number, md5?: string, extension?: string, fileType?: string, fileName?: string, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreAdministratorExamsSetSuccessUploadStatusPut(examId, examFileId, md5, extension, fileType, fileName, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject16} inlineObject16 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreExamAssociatePost(inlineObject16: InlineObject16, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExamViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreExamAssociatePost(inlineObject16, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} examId 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreExamGetAssociationCandidatesExamIdGet(examId: number, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExamViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreExamGetAssociationCandidatesExamIdGet(examId, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [specialityId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreExamSourceCategoryGet(specialityId?: number, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpecialityViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreExamSourceCategoryGet(specialityId, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [sourceId] 
         * @param {string} [cpf] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreExamSourceExamsByDocumentGet(sourceId?: number, cpf?: string, page?: number, pageSize?: number, notifications?: Array<Notification>, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExamViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreExamSourceExamsByDocumentGet(sourceId, cpf, page, pageSize, notifications, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} examId 
         * @param {InlineObject3} inlineObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreExamSourceExamsExamIdPut(examId: number, inlineObject3: InlineObject3, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreExamSourceExamsExamIdPut(examId, inlineObject3, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} examId 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreExamSourceExamsExamIdReportGet(examId: number, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreExamSourceExamsExamIdReportGet(examId, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [sourceId] 
         * @param {number} [examId] 
         * @param {string} [patientName] 
         * @param {number} [categoryId] 
         * @param {number} [specialityId] 
         * @param {number} [statusId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [examStartDate] 
         * @param {string} [examEndDate] 
         * @param {string} [orderColumn] 
         * @param {string} [orderBy] 
         * @param {boolean} [printed] 
         * @param {boolean} [urgent] 
         * @param {boolean} [urgencyAlert] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [cpf] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreExamSourceExamsGet(sourceId?: number, examId?: number, patientName?: string, categoryId?: number, specialityId?: number, statusId?: number, page?: number, pageSize?: number, examStartDate?: string, examEndDate?: string, orderColumn?: string, orderBy?: string, printed?: boolean, urgent?: boolean, urgencyAlert?: boolean, notifications?: Array<Notification>, cpf?: string, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExamViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreExamSourceExamsGet(sourceId, examId, patientName, categoryId, specialityId, statusId, page, pageSize, examStartDate, examEndDate, orderColumn, orderBy, printed, urgent, urgencyAlert, notifications, cpf, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject7} inlineObject7 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreExamSourceExamsObservationPost(inlineObject7: InlineObject7, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreExamSourceExamsObservationPost(inlineObject7, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} [examsId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreExamSourceExamsReportsGet(examsId?: Array<number>, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreExamSourceExamsReportsGet(examsId, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject2} inlineObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreExamSourceExamsSetUrgentExamPut(inlineObject2: InlineObject2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreExamSourceExamsSetUrgentExamPut(inlineObject2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreExamSourceExamsStatusGet(xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExamStatusViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreExamSourceExamsStatusGet(xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreExamsInformationGet(examId?: number, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExamInformationViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreExamsInformationGet(examId, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject10} inlineObject10 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreExamsInformationPost(inlineObject10: InlineObject10, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExamInformationViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreExamsInformationPost(inlineObject10, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject5} inlineObject5 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreExamsSendReviewPost(inlineObject5: InlineObject5, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreExamsSendReviewPost(inlineObject5, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject4} inlineObject4 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCorePhysicianExamsLaudarInsertMedicalReportPost(inlineObject4: InlineObject4, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCorePhysicianExamsLaudarInsertMedicalReportPost(inlineObject4, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCorePhysicianExamsQueueGetItemGet(xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExamQueueItemViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCorePhysicianExamsQueueGetItemGet(xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCorePhysicianExamsQueueHasNextGet(xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCorePhysicianExamsQueueHasNextGet(xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [specialityId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCorePhysicianExamsReasonsToRepeatExamBySpecialityIdGet(specialityId?: number, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExamRepetitionReasonViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCorePhysicianExamsReasonsToRepeatExamBySpecialityIdGet(specialityId, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject6} inlineObject6 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCorePhysicianExamsRepeatExamPut(inlineObject6: InlineObject6, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCorePhysicianExamsRepeatExamPut(inlineObject6, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {ExamUpdateViewModel} [examUpdateViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCorePhysicianExamsUnassignExamMedicPut(xAuth?: string, examUpdateViewModel?: ExamUpdateViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCorePhysicianExamsUnassignExamMedicPut(xAuth, examUpdateViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [sourceId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreUrgencyAlertGetAllNoCheckedAlertsGet(sourceId?: number, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreUrgencyAlertGetAllNoCheckedAlertsGet(sourceId, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreUrgencyAlertGetUrgencyAlertsForAdminGet(examId?: number, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreUrgencyAlertGetUrgencyAlertsForAdminGet(examId, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {InlineObject20} [inlineObject20] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreUrgencyAlertPostIKnowPut(xAuth?: string, inlineObject20?: InlineObject20, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreUrgencyAlertPostIKnowPut(xAuth, inlineObject20, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [sourceId] 
         * @param {number} [examId] 
         * @param {string} [patientName] 
         * @param {number} [categoryId] 
         * @param {number} [specialityId] 
         * @param {Array<number>} [statusId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [examStartDate] 
         * @param {string} [examEndDate] 
         * @param {string} [orderColumn] 
         * @param {string} [orderBy] 
         * @param {boolean} [printed] 
         * @param {boolean} [urgent] 
         * @param {boolean} [urgencyAlert] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPtmsyncPreOrderGet(sourceId?: number, examId?: number, patientName?: string, categoryId?: number, specialityId?: number, statusId?: Array<number>, page?: number, pageSize?: number, examStartDate?: string, examEndDate?: string, orderColumn?: string, orderBy?: string, printed?: boolean, urgent?: boolean, urgencyAlert?: boolean, notifications?: Array<Notification>, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPtmsyncPreOrderGet(sourceId, examId, patientName, categoryId, specialityId, statusId, page, pageSize, examStartDate, examEndDate, orderColumn, orderBy, printed, urgent, urgencyAlert, notifications, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {CancelDuplicatesRequestViewModel} [cancelDuplicatesRequestViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examCancelDuplicatesPost(xAuth?: string, cancelDuplicatesRequestViewModel?: CancelDuplicatesRequestViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examCancelDuplicatesPost(xAuth, cancelDuplicatesRequestViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examDuplicatesGet(name?: string, month?: number, year?: number, notifications?: Array<Notification>, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DuplicatesResponseViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examDuplicatesGet(name, month, year, notifications, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examRepeatTimeoutPost(xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExamViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examRepeatTimeoutPost(xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [category] 
         * @param {number} [status] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examSimpleListGet(category?: number, status?: number, page?: number, pageSize?: number, notifications?: Array<Notification>, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examSimpleListGet(category, status, page, pageSize, notifications, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {ExamUpdateViewModel} [examUpdateViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examSourceExamsSetUrgentExamPut(xAuth?: string, examUpdateViewModel?: ExamUpdateViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examSourceExamsSetUrgentExamPut(xAuth, examUpdateViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examUpdatedExamsGet(notifications?: Array<Notification>, startDate?: string, endDate?: string, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExamStatusHistoryExportViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examUpdatedExamsGet(notifications, startDate, endDate, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examsCountersGet(startDate?: string, endDate?: string, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExamCountersViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examsCountersGet(startDate, endDate, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examsQueuePopulateGet(xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExamPrioritasPopulateQueueViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examsQueuePopulateGet(xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {ExamSpecialityChangeViewModel} [examSpecialityChangeViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examsSpecialityPut(xAuth?: string, examSpecialityChangeViewModel?: ExamSpecialityChangeViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examsSpecialityPut(xAuth, examSpecialityChangeViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} examId 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async physicianExamsExamIdExamFileGet(examId: number, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.physicianExamsExamIdExamFileGet(examId, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async physicianExamsReportedGet(xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExamResultViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.physicianExamsReportedGet(xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {ExamUpdateViewModel} [examUpdateViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async physicianExamsUpdateDateLasUpdatetExamPut(xAuth?: string, examUpdateViewModel?: ExamUpdateViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.physicianExamsUpdateDateLasUpdatetExamPut(xAuth, examUpdateViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExamApi - factory interface
 * @export
 */
export const ExamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExamApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [xAuth] 
         * @param {PostDivergenceViewModel} [postDivergenceViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamConfirmDivergencePost(xAuth?: string, postDivergenceViewModel?: PostDivergenceViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.administratorExamConfirmDivergencePost(xAuth, postDivergenceViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamDivergenceGet(examId?: number, xAuth?: string, options?: any): AxiosPromise<ReportDivergenceViewModel> {
            return localVarFp.administratorExamDivergenceGet(examId, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {CdpDivergenceResolveViewModel} [cdpDivergenceResolveViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamDivergenceResolvePost(xAuth?: string, cdpDivergenceResolveViewModel?: CdpDivergenceResolveViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.administratorExamDivergenceResolvePost(xAuth, cdpDivergenceResolveViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {PostDivergenceViewModel} [postDivergenceViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamDivergenceUpdatePost(xAuth?: string, postDivergenceViewModel?: PostDivergenceViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.administratorExamDivergenceUpdatePost(xAuth, postDivergenceViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamPatientAgeFromExamIdGet(examId?: number, xAuth?: string, options?: any): AxiosPromise<number> {
            return localVarFp.administratorExamPatientAgeFromExamIdGet(examId, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamsAIStudyGet(examId?: number, xAuth?: string, options?: any): AxiosPromise<ExamAIStudyViewModel> {
            return localVarFp.administratorExamsAIStudyGet(examId, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {ExamAIStudyViewModel} [examAIStudyViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamsAIStudyPost(xAuth?: string, examAIStudyViewModel?: ExamAIStudyViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.administratorExamsAIStudyPost(xAuth, examAIStudyViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamsComparisonGet(examId?: number, xAuth?: string, options?: any): AxiosPromise<AilaResultComparisonReportsViewModel> {
            return localVarFp.administratorExamsComparisonGet(examId, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {AilaEvaluateExamComparisonViewModel} [ailaEvaluateExamComparisonViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamsEvaluateAilaResultPut(xAuth?: string, ailaEvaluateExamComparisonViewModel?: AilaEvaluateExamComparisonViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.administratorExamsEvaluateAilaResultPut(xAuth, ailaEvaluateExamComparisonViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [patientName] 
         * @param {number} [categoryId] 
         * @param {number} [specialityId] 
         * @param {StatusExamsEnum} [statusId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [examStartDate] 
         * @param {string} [examEndDate] 
         * @param {string} [orderColumn] 
         * @param {string} [orderBy] 
         * @param {string} [lastReportStartDate] 
         * @param {string} [lastReportEndDate] 
         * @param {boolean} [printed] 
         * @param {Array<number>} [sourceId] 
         * @param {number} [physicianId] 
         * @param {boolean} [internalSystem] 
         * @param {boolean} [urgencyAlert] 
         * @param {boolean} [divergence] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamsGet(examId?: number, patientName?: string, categoryId?: number, specialityId?: number, statusId?: StatusExamsEnum, page?: number, pageSize?: number, examStartDate?: string, examEndDate?: string, orderColumn?: string, orderBy?: string, lastReportStartDate?: string, lastReportEndDate?: string, printed?: boolean, sourceId?: Array<number>, physicianId?: number, internalSystem?: boolean, urgencyAlert?: boolean, divergence?: boolean, notifications?: Array<Notification>, xAuth?: string, options?: any): AxiosPromise<AdminExamViewModel> {
            return localVarFp.administratorExamsGet(examId, patientName, categoryId, specialityId, statusId, page, pageSize, examStartDate, examEndDate, orderColumn, orderBy, lastReportStartDate, lastReportEndDate, printed, sourceId, physicianId, internalSystem, urgencyAlert, divergence, notifications, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {AdminPostObservationViewModel} [adminPostObservationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamsObservationPost(xAuth?: string, adminPostObservationViewModel?: AdminPostObservationViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.administratorExamsObservationPost(xAuth, adminPostObservationViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {AdminPullViewModel} [adminPullViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamsPullPost(xAuth?: string, adminPullViewModel?: AdminPullViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.administratorExamsPullPost(xAuth, adminPullViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {ReleaseReportViewModel} [releaseReportViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamsReleaseReportPost(xAuth?: string, releaseReportViewModel?: ReleaseReportViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.administratorExamsReleaseReportPost(xAuth, releaseReportViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamsRetificationReasonsGet(xAuth?: string, options?: any): AxiosPromise<ExamCountersViewModel> {
            return localVarFp.administratorExamsRetificationReasonsGet(xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analystExamsQueueHasNextGet(xAuth?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.analystExamsQueueHasNextGet(xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAtenaAdminUserGet(page?: number, pageSize?: number, xAuth?: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiAtenaAdminUserGet(page, pageSize, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject8} inlineObject8 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAtenaAdminUserPut(inlineObject8: InlineObject8, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAtenaAdminUserPut(inlineObject8, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject14} inlineObject14 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamAssignExamToPhysicianPost(inlineObject14: InlineObject14, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCoreAdministratorExamAssignExamToPhysicianPost(inlineObject14, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject11} inlineObject11 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamReplaceReportDocumentPost(inlineObject11: InlineObject11, xAuth?: string, options?: any): AxiosPromise<ExamReportDocumentRepleacedViewModel> {
            return localVarFp.apiCoreAdministratorExamReplaceReportDocumentPost(inlineObject11, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {number} [examFileId] 
         * @param {string} [md5] 
         * @param {string} [extension] 
         * @param {string} [fileType] 
         * @param {string} [fileName] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamSignedUrlGet(examId?: number, examFileId?: number, md5?: string, extension?: string, fileType?: string, fileName?: string, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCoreAdministratorExamSignedUrlGet(examId, examFileId, md5, extension, fileType, fileName, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject15} inlineObject15 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamsCancelPut(inlineObject15: InlineObject15, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCoreAdministratorExamsCancelPut(inlineObject15, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject13} inlineObject13 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamsChangePhysicianPut(inlineObject13: InlineObject13, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCoreAdministratorExamsChangePhysicianPut(inlineObject13, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamsDetailsGet(examId?: number, xAuth?: string, options?: any): AxiosPromise<ExamDetailsViewModel> {
            return localVarFp.apiCoreAdministratorExamsDetailsGet(examId, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [examFileId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamsFileGet(examFileId?: number, xAuth?: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiCoreAdministratorExamsFileGet(examFileId, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [physicianName] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamsPhysiciansForExamGet(examId?: number, physicianName?: string, page?: number, pageSize?: number, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCoreAdministratorExamsPhysiciansForExamGet(examId, physicianName, page, pageSize, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject12} inlineObject12 
         * @param {number} [examId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamsRetificationPost(inlineObject12: InlineObject12, examId?: number, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCoreAdministratorExamsRetificationPost(inlineObject12, examId, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {number} [examFileId] 
         * @param {string} [md5] 
         * @param {string} [extension] 
         * @param {string} [fileType] 
         * @param {string} [fileName] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamsSetSuccessUploadStatusPut(examId?: number, examFileId?: number, md5?: string, extension?: string, fileType?: string, fileName?: string, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCoreAdministratorExamsSetSuccessUploadStatusPut(examId, examFileId, md5, extension, fileType, fileName, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject16} inlineObject16 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamAssociatePost(inlineObject16: InlineObject16, xAuth?: string, options?: any): AxiosPromise<ExamViewModel> {
            return localVarFp.apiCoreExamAssociatePost(inlineObject16, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} examId 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamGetAssociationCandidatesExamIdGet(examId: number, xAuth?: string, options?: any): AxiosPromise<ExamViewModel> {
            return localVarFp.apiCoreExamGetAssociationCandidatesExamIdGet(examId, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [specialityId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceCategoryGet(specialityId?: number, xAuth?: string, options?: any): AxiosPromise<Array<SpecialityViewModel>> {
            return localVarFp.apiCoreExamSourceCategoryGet(specialityId, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [sourceId] 
         * @param {string} [cpf] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceExamsByDocumentGet(sourceId?: number, cpf?: string, page?: number, pageSize?: number, notifications?: Array<Notification>, xAuth?: string, options?: any): AxiosPromise<ExamViewModel> {
            return localVarFp.apiCoreExamSourceExamsByDocumentGet(sourceId, cpf, page, pageSize, notifications, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} examId 
         * @param {InlineObject3} inlineObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceExamsExamIdPut(examId: number, inlineObject3: InlineObject3, options?: any): AxiosPromise<void> {
            return localVarFp.apiCoreExamSourceExamsExamIdPut(examId, inlineObject3, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} examId 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceExamsExamIdReportGet(examId: number, xAuth?: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiCoreExamSourceExamsExamIdReportGet(examId, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [sourceId] 
         * @param {number} [examId] 
         * @param {string} [patientName] 
         * @param {number} [categoryId] 
         * @param {number} [specialityId] 
         * @param {number} [statusId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [examStartDate] 
         * @param {string} [examEndDate] 
         * @param {string} [orderColumn] 
         * @param {string} [orderBy] 
         * @param {boolean} [printed] 
         * @param {boolean} [urgent] 
         * @param {boolean} [urgencyAlert] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [cpf] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceExamsGet(sourceId?: number, examId?: number, patientName?: string, categoryId?: number, specialityId?: number, statusId?: number, page?: number, pageSize?: number, examStartDate?: string, examEndDate?: string, orderColumn?: string, orderBy?: string, printed?: boolean, urgent?: boolean, urgencyAlert?: boolean, notifications?: Array<Notification>, cpf?: string, xAuth?: string, options?: any): AxiosPromise<ExamViewModel> {
            return localVarFp.apiCoreExamSourceExamsGet(sourceId, examId, patientName, categoryId, specialityId, statusId, page, pageSize, examStartDate, examEndDate, orderColumn, orderBy, printed, urgent, urgencyAlert, notifications, cpf, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject7} inlineObject7 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceExamsObservationPost(inlineObject7: InlineObject7, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCoreExamSourceExamsObservationPost(inlineObject7, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} [examsId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceExamsReportsGet(examsId?: Array<number>, xAuth?: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiCoreExamSourceExamsReportsGet(examsId, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject2} inlineObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceExamsSetUrgentExamPut(inlineObject2: InlineObject2, options?: any): AxiosPromise<void> {
            return localVarFp.apiCoreExamSourceExamsSetUrgentExamPut(inlineObject2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceExamsStatusGet(xAuth?: string, options?: any): AxiosPromise<Array<ExamStatusViewModel>> {
            return localVarFp.apiCoreExamSourceExamsStatusGet(xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamsInformationGet(examId?: number, xAuth?: string, options?: any): AxiosPromise<Array<ExamInformationViewModel>> {
            return localVarFp.apiCoreExamsInformationGet(examId, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject10} inlineObject10 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamsInformationPost(inlineObject10: InlineObject10, xAuth?: string, options?: any): AxiosPromise<Array<ExamInformationViewModel>> {
            return localVarFp.apiCoreExamsInformationPost(inlineObject10, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject5} inlineObject5 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamsSendReviewPost(inlineObject5: InlineObject5, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCoreExamsSendReviewPost(inlineObject5, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject4} inlineObject4 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCorePhysicianExamsLaudarInsertMedicalReportPost(inlineObject4: InlineObject4, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCorePhysicianExamsLaudarInsertMedicalReportPost(inlineObject4, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCorePhysicianExamsQueueGetItemGet(xAuth?: string, options?: any): AxiosPromise<ExamQueueItemViewModel> {
            return localVarFp.apiCorePhysicianExamsQueueGetItemGet(xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCorePhysicianExamsQueueHasNextGet(xAuth?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiCorePhysicianExamsQueueHasNextGet(xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [specialityId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCorePhysicianExamsReasonsToRepeatExamBySpecialityIdGet(specialityId?: number, xAuth?: string, options?: any): AxiosPromise<Array<ExamRepetitionReasonViewModel>> {
            return localVarFp.apiCorePhysicianExamsReasonsToRepeatExamBySpecialityIdGet(specialityId, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject6} inlineObject6 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCorePhysicianExamsRepeatExamPut(inlineObject6: InlineObject6, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCorePhysicianExamsRepeatExamPut(inlineObject6, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {ExamUpdateViewModel} [examUpdateViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCorePhysicianExamsUnassignExamMedicPut(xAuth?: string, examUpdateViewModel?: ExamUpdateViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiCorePhysicianExamsUnassignExamMedicPut(xAuth, examUpdateViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [sourceId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreUrgencyAlertGetAllNoCheckedAlertsGet(sourceId?: number, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCoreUrgencyAlertGetAllNoCheckedAlertsGet(sourceId, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [examId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreUrgencyAlertGetUrgencyAlertsForAdminGet(examId?: number, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCoreUrgencyAlertGetUrgencyAlertsForAdminGet(examId, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {InlineObject20} [inlineObject20] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreUrgencyAlertPostIKnowPut(xAuth?: string, inlineObject20?: InlineObject20, options?: any): AxiosPromise<void> {
            return localVarFp.apiCoreUrgencyAlertPostIKnowPut(xAuth, inlineObject20, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [sourceId] 
         * @param {number} [examId] 
         * @param {string} [patientName] 
         * @param {number} [categoryId] 
         * @param {number} [specialityId] 
         * @param {Array<number>} [statusId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [examStartDate] 
         * @param {string} [examEndDate] 
         * @param {string} [orderColumn] 
         * @param {string} [orderBy] 
         * @param {boolean} [printed] 
         * @param {boolean} [urgent] 
         * @param {boolean} [urgencyAlert] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPtmsyncPreOrderGet(sourceId?: number, examId?: number, patientName?: string, categoryId?: number, specialityId?: number, statusId?: Array<number>, page?: number, pageSize?: number, examStartDate?: string, examEndDate?: string, orderColumn?: string, orderBy?: string, printed?: boolean, urgent?: boolean, urgencyAlert?: boolean, notifications?: Array<Notification>, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPtmsyncPreOrderGet(sourceId, examId, patientName, categoryId, specialityId, statusId, page, pageSize, examStartDate, examEndDate, orderColumn, orderBy, printed, urgent, urgencyAlert, notifications, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {CancelDuplicatesRequestViewModel} [cancelDuplicatesRequestViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examCancelDuplicatesPost(xAuth?: string, cancelDuplicatesRequestViewModel?: CancelDuplicatesRequestViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.examCancelDuplicatesPost(xAuth, cancelDuplicatesRequestViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examDuplicatesGet(name?: string, month?: number, year?: number, notifications?: Array<Notification>, xAuth?: string, options?: any): AxiosPromise<DuplicatesResponseViewModel> {
            return localVarFp.examDuplicatesGet(name, month, year, notifications, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examRepeatTimeoutPost(xAuth?: string, options?: any): AxiosPromise<ExamViewModel> {
            return localVarFp.examRepeatTimeoutPost(xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [category] 
         * @param {number} [status] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSimpleListGet(category?: number, status?: number, page?: number, pageSize?: number, notifications?: Array<Notification>, xAuth?: string, options?: any): AxiosPromise<SimpleListViewModel> {
            return localVarFp.examSimpleListGet(category, status, page, pageSize, notifications, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {ExamUpdateViewModel} [examUpdateViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSourceExamsSetUrgentExamPut(xAuth?: string, examUpdateViewModel?: ExamUpdateViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.examSourceExamsSetUrgentExamPut(xAuth, examUpdateViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examUpdatedExamsGet(notifications?: Array<Notification>, startDate?: string, endDate?: string, xAuth?: string, options?: any): AxiosPromise<ExamStatusHistoryExportViewModel> {
            return localVarFp.examUpdatedExamsGet(notifications, startDate, endDate, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsCountersGet(startDate?: string, endDate?: string, xAuth?: string, options?: any): AxiosPromise<ExamCountersViewModel> {
            return localVarFp.examsCountersGet(startDate, endDate, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsQueuePopulateGet(xAuth?: string, options?: any): AxiosPromise<ExamPrioritasPopulateQueueViewModel> {
            return localVarFp.examsQueuePopulateGet(xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {ExamSpecialityChangeViewModel} [examSpecialityChangeViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsSpecialityPut(xAuth?: string, examSpecialityChangeViewModel?: ExamSpecialityChangeViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.examsSpecialityPut(xAuth, examSpecialityChangeViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} examId 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        physicianExamsExamIdExamFileGet(examId: number, xAuth?: string, options?: any): AxiosPromise<any> {
            return localVarFp.physicianExamsExamIdExamFileGet(examId, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        physicianExamsReportedGet(xAuth?: string, options?: any): AxiosPromise<Array<ExamResultViewModel>> {
            return localVarFp.physicianExamsReportedGet(xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {ExamUpdateViewModel} [examUpdateViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        physicianExamsUpdateDateLasUpdatetExamPut(xAuth?: string, examUpdateViewModel?: ExamUpdateViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.physicianExamsUpdateDateLasUpdatetExamPut(xAuth, examUpdateViewModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for administratorExamConfirmDivergencePost operation in ExamApi.
 * @export
 * @interface ExamApiAdministratorExamConfirmDivergencePostRequest
 */
export interface ExamApiAdministratorExamConfirmDivergencePostRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamApiAdministratorExamConfirmDivergencePost
     */
    readonly xAuth?: string

    /**
     * 
     * @type {PostDivergenceViewModel}
     * @memberof ExamApiAdministratorExamConfirmDivergencePost
     */
    readonly postDivergenceViewModel?: PostDivergenceViewModel
}

/**
 * Request parameters for administratorExamDivergenceGet operation in ExamApi.
 * @export
 * @interface ExamApiAdministratorExamDivergenceGetRequest
 */
export interface ExamApiAdministratorExamDivergenceGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiAdministratorExamDivergenceGet
     */
    readonly examId?: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiAdministratorExamDivergenceGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for administratorExamDivergenceResolvePost operation in ExamApi.
 * @export
 * @interface ExamApiAdministratorExamDivergenceResolvePostRequest
 */
export interface ExamApiAdministratorExamDivergenceResolvePostRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamApiAdministratorExamDivergenceResolvePost
     */
    readonly xAuth?: string

    /**
     * 
     * @type {CdpDivergenceResolveViewModel}
     * @memberof ExamApiAdministratorExamDivergenceResolvePost
     */
    readonly cdpDivergenceResolveViewModel?: CdpDivergenceResolveViewModel
}

/**
 * Request parameters for administratorExamDivergenceUpdatePost operation in ExamApi.
 * @export
 * @interface ExamApiAdministratorExamDivergenceUpdatePostRequest
 */
export interface ExamApiAdministratorExamDivergenceUpdatePostRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamApiAdministratorExamDivergenceUpdatePost
     */
    readonly xAuth?: string

    /**
     * 
     * @type {PostDivergenceViewModel}
     * @memberof ExamApiAdministratorExamDivergenceUpdatePost
     */
    readonly postDivergenceViewModel?: PostDivergenceViewModel
}

/**
 * Request parameters for administratorExamPatientAgeFromExamIdGet operation in ExamApi.
 * @export
 * @interface ExamApiAdministratorExamPatientAgeFromExamIdGetRequest
 */
export interface ExamApiAdministratorExamPatientAgeFromExamIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiAdministratorExamPatientAgeFromExamIdGet
     */
    readonly examId?: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiAdministratorExamPatientAgeFromExamIdGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for administratorExamsAIStudyGet operation in ExamApi.
 * @export
 * @interface ExamApiAdministratorExamsAIStudyGetRequest
 */
export interface ExamApiAdministratorExamsAIStudyGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiAdministratorExamsAIStudyGet
     */
    readonly examId?: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiAdministratorExamsAIStudyGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for administratorExamsAIStudyPost operation in ExamApi.
 * @export
 * @interface ExamApiAdministratorExamsAIStudyPostRequest
 */
export interface ExamApiAdministratorExamsAIStudyPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamApiAdministratorExamsAIStudyPost
     */
    readonly xAuth?: string

    /**
     * 
     * @type {ExamAIStudyViewModel}
     * @memberof ExamApiAdministratorExamsAIStudyPost
     */
    readonly examAIStudyViewModel?: ExamAIStudyViewModel
}

/**
 * Request parameters for administratorExamsComparisonGet operation in ExamApi.
 * @export
 * @interface ExamApiAdministratorExamsComparisonGetRequest
 */
export interface ExamApiAdministratorExamsComparisonGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiAdministratorExamsComparisonGet
     */
    readonly examId?: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiAdministratorExamsComparisonGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for administratorExamsEvaluateAilaResultPut operation in ExamApi.
 * @export
 * @interface ExamApiAdministratorExamsEvaluateAilaResultPutRequest
 */
export interface ExamApiAdministratorExamsEvaluateAilaResultPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamApiAdministratorExamsEvaluateAilaResultPut
     */
    readonly xAuth?: string

    /**
     * 
     * @type {AilaEvaluateExamComparisonViewModel}
     * @memberof ExamApiAdministratorExamsEvaluateAilaResultPut
     */
    readonly ailaEvaluateExamComparisonViewModel?: AilaEvaluateExamComparisonViewModel
}

/**
 * Request parameters for administratorExamsGet operation in ExamApi.
 * @export
 * @interface ExamApiAdministratorExamsGetRequest
 */
export interface ExamApiAdministratorExamsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiAdministratorExamsGet
     */
    readonly examId?: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiAdministratorExamsGet
     */
    readonly patientName?: string

    /**
     * 
     * @type {number}
     * @memberof ExamApiAdministratorExamsGet
     */
    readonly categoryId?: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiAdministratorExamsGet
     */
    readonly specialityId?: number

    /**
     * 
     * @type {StatusExamsEnum}
     * @memberof ExamApiAdministratorExamsGet
     */
    readonly statusId?: StatusExamsEnum

    /**
     * 
     * @type {number}
     * @memberof ExamApiAdministratorExamsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiAdministratorExamsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiAdministratorExamsGet
     */
    readonly examStartDate?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiAdministratorExamsGet
     */
    readonly examEndDate?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiAdministratorExamsGet
     */
    readonly orderColumn?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiAdministratorExamsGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiAdministratorExamsGet
     */
    readonly lastReportStartDate?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiAdministratorExamsGet
     */
    readonly lastReportEndDate?: string

    /**
     * 
     * @type {boolean}
     * @memberof ExamApiAdministratorExamsGet
     */
    readonly printed?: boolean

    /**
     * 
     * @type {Array<number>}
     * @memberof ExamApiAdministratorExamsGet
     */
    readonly sourceId?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof ExamApiAdministratorExamsGet
     */
    readonly physicianId?: number

    /**
     * 
     * @type {boolean}
     * @memberof ExamApiAdministratorExamsGet
     */
    readonly internalSystem?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ExamApiAdministratorExamsGet
     */
    readonly urgencyAlert?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ExamApiAdministratorExamsGet
     */
    readonly divergence?: boolean

    /**
     * 
     * @type {Array<Notification>}
     * @memberof ExamApiAdministratorExamsGet
     */
    readonly notifications?: Array<Notification>

    /**
     * 
     * @type {string}
     * @memberof ExamApiAdministratorExamsGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for administratorExamsObservationPost operation in ExamApi.
 * @export
 * @interface ExamApiAdministratorExamsObservationPostRequest
 */
export interface ExamApiAdministratorExamsObservationPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamApiAdministratorExamsObservationPost
     */
    readonly xAuth?: string

    /**
     * 
     * @type {AdminPostObservationViewModel}
     * @memberof ExamApiAdministratorExamsObservationPost
     */
    readonly adminPostObservationViewModel?: AdminPostObservationViewModel
}

/**
 * Request parameters for administratorExamsPullPost operation in ExamApi.
 * @export
 * @interface ExamApiAdministratorExamsPullPostRequest
 */
export interface ExamApiAdministratorExamsPullPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamApiAdministratorExamsPullPost
     */
    readonly xAuth?: string

    /**
     * 
     * @type {AdminPullViewModel}
     * @memberof ExamApiAdministratorExamsPullPost
     */
    readonly adminPullViewModel?: AdminPullViewModel
}

/**
 * Request parameters for administratorExamsReleaseReportPost operation in ExamApi.
 * @export
 * @interface ExamApiAdministratorExamsReleaseReportPostRequest
 */
export interface ExamApiAdministratorExamsReleaseReportPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamApiAdministratorExamsReleaseReportPost
     */
    readonly xAuth?: string

    /**
     * 
     * @type {ReleaseReportViewModel}
     * @memberof ExamApiAdministratorExamsReleaseReportPost
     */
    readonly releaseReportViewModel?: ReleaseReportViewModel
}

/**
 * Request parameters for administratorExamsRetificationReasonsGet operation in ExamApi.
 * @export
 * @interface ExamApiAdministratorExamsRetificationReasonsGetRequest
 */
export interface ExamApiAdministratorExamsRetificationReasonsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamApiAdministratorExamsRetificationReasonsGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for analystExamsQueueHasNextGet operation in ExamApi.
 * @export
 * @interface ExamApiAnalystExamsQueueHasNextGetRequest
 */
export interface ExamApiAnalystExamsQueueHasNextGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamApiAnalystExamsQueueHasNextGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiAtenaAdminUserGet operation in ExamApi.
 * @export
 * @interface ExamApiApiAtenaAdminUserGetRequest
 */
export interface ExamApiApiAtenaAdminUserGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiApiAtenaAdminUserGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiApiAtenaAdminUserGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiAtenaAdminUserGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiAtenaAdminUserPut operation in ExamApi.
 * @export
 * @interface ExamApiApiAtenaAdminUserPutRequest
 */
export interface ExamApiApiAtenaAdminUserPutRequest {
    /**
     * 
     * @type {InlineObject8}
     * @memberof ExamApiApiAtenaAdminUserPut
     */
    readonly inlineObject8: InlineObject8

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiAtenaAdminUserPut
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreAdministratorExamAssignExamToPhysicianPost operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreAdministratorExamAssignExamToPhysicianPostRequest
 */
export interface ExamApiApiCoreAdministratorExamAssignExamToPhysicianPostRequest {
    /**
     * 
     * @type {InlineObject14}
     * @memberof ExamApiApiCoreAdministratorExamAssignExamToPhysicianPost
     */
    readonly inlineObject14: InlineObject14

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreAdministratorExamAssignExamToPhysicianPost
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreAdministratorExamReplaceReportDocumentPost operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreAdministratorExamReplaceReportDocumentPostRequest
 */
export interface ExamApiApiCoreAdministratorExamReplaceReportDocumentPostRequest {
    /**
     * 
     * @type {InlineObject11}
     * @memberof ExamApiApiCoreAdministratorExamReplaceReportDocumentPost
     */
    readonly inlineObject11: InlineObject11

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreAdministratorExamReplaceReportDocumentPost
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreAdministratorExamSignedUrlGet operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreAdministratorExamSignedUrlGetRequest
 */
export interface ExamApiApiCoreAdministratorExamSignedUrlGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreAdministratorExamSignedUrlGet
     */
    readonly examId?: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreAdministratorExamSignedUrlGet
     */
    readonly examFileId?: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreAdministratorExamSignedUrlGet
     */
    readonly md5?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreAdministratorExamSignedUrlGet
     */
    readonly extension?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreAdministratorExamSignedUrlGet
     */
    readonly fileType?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreAdministratorExamSignedUrlGet
     */
    readonly fileName?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreAdministratorExamSignedUrlGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreAdministratorExamsCancelPut operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreAdministratorExamsCancelPutRequest
 */
export interface ExamApiApiCoreAdministratorExamsCancelPutRequest {
    /**
     * 
     * @type {InlineObject15}
     * @memberof ExamApiApiCoreAdministratorExamsCancelPut
     */
    readonly inlineObject15: InlineObject15

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreAdministratorExamsCancelPut
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreAdministratorExamsChangePhysicianPut operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreAdministratorExamsChangePhysicianPutRequest
 */
export interface ExamApiApiCoreAdministratorExamsChangePhysicianPutRequest {
    /**
     * 
     * @type {InlineObject13}
     * @memberof ExamApiApiCoreAdministratorExamsChangePhysicianPut
     */
    readonly inlineObject13: InlineObject13

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreAdministratorExamsChangePhysicianPut
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreAdministratorExamsDetailsGet operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreAdministratorExamsDetailsGetRequest
 */
export interface ExamApiApiCoreAdministratorExamsDetailsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreAdministratorExamsDetailsGet
     */
    readonly examId?: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreAdministratorExamsDetailsGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreAdministratorExamsFileGet operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreAdministratorExamsFileGetRequest
 */
export interface ExamApiApiCoreAdministratorExamsFileGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreAdministratorExamsFileGet
     */
    readonly examFileId?: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreAdministratorExamsFileGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreAdministratorExamsPhysiciansForExamGet operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreAdministratorExamsPhysiciansForExamGetRequest
 */
export interface ExamApiApiCoreAdministratorExamsPhysiciansForExamGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreAdministratorExamsPhysiciansForExamGet
     */
    readonly examId?: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreAdministratorExamsPhysiciansForExamGet
     */
    readonly physicianName?: string

    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreAdministratorExamsPhysiciansForExamGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreAdministratorExamsPhysiciansForExamGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreAdministratorExamsPhysiciansForExamGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreAdministratorExamsRetificationPost operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreAdministratorExamsRetificationPostRequest
 */
export interface ExamApiApiCoreAdministratorExamsRetificationPostRequest {
    /**
     * 
     * @type {InlineObject12}
     * @memberof ExamApiApiCoreAdministratorExamsRetificationPost
     */
    readonly inlineObject12: InlineObject12

    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreAdministratorExamsRetificationPost
     */
    readonly examId?: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreAdministratorExamsRetificationPost
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreAdministratorExamsSetSuccessUploadStatusPut operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreAdministratorExamsSetSuccessUploadStatusPutRequest
 */
export interface ExamApiApiCoreAdministratorExamsSetSuccessUploadStatusPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreAdministratorExamsSetSuccessUploadStatusPut
     */
    readonly examId?: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreAdministratorExamsSetSuccessUploadStatusPut
     */
    readonly examFileId?: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreAdministratorExamsSetSuccessUploadStatusPut
     */
    readonly md5?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreAdministratorExamsSetSuccessUploadStatusPut
     */
    readonly extension?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreAdministratorExamsSetSuccessUploadStatusPut
     */
    readonly fileType?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreAdministratorExamsSetSuccessUploadStatusPut
     */
    readonly fileName?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreAdministratorExamsSetSuccessUploadStatusPut
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreExamAssociatePost operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreExamAssociatePostRequest
 */
export interface ExamApiApiCoreExamAssociatePostRequest {
    /**
     * 
     * @type {InlineObject16}
     * @memberof ExamApiApiCoreExamAssociatePost
     */
    readonly inlineObject16: InlineObject16

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreExamAssociatePost
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreExamGetAssociationCandidatesExamIdGet operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreExamGetAssociationCandidatesExamIdGetRequest
 */
export interface ExamApiApiCoreExamGetAssociationCandidatesExamIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreExamGetAssociationCandidatesExamIdGet
     */
    readonly examId: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreExamGetAssociationCandidatesExamIdGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreExamSourceCategoryGet operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreExamSourceCategoryGetRequest
 */
export interface ExamApiApiCoreExamSourceCategoryGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreExamSourceCategoryGet
     */
    readonly specialityId?: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreExamSourceCategoryGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreExamSourceExamsByDocumentGet operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreExamSourceExamsByDocumentGetRequest
 */
export interface ExamApiApiCoreExamSourceExamsByDocumentGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreExamSourceExamsByDocumentGet
     */
    readonly sourceId?: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreExamSourceExamsByDocumentGet
     */
    readonly cpf?: string

    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreExamSourceExamsByDocumentGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreExamSourceExamsByDocumentGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {Array<Notification>}
     * @memberof ExamApiApiCoreExamSourceExamsByDocumentGet
     */
    readonly notifications?: Array<Notification>

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreExamSourceExamsByDocumentGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreExamSourceExamsExamIdPut operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreExamSourceExamsExamIdPutRequest
 */
export interface ExamApiApiCoreExamSourceExamsExamIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreExamSourceExamsExamIdPut
     */
    readonly examId: number

    /**
     * 
     * @type {InlineObject3}
     * @memberof ExamApiApiCoreExamSourceExamsExamIdPut
     */
    readonly inlineObject3: InlineObject3
}

/**
 * Request parameters for apiCoreExamSourceExamsExamIdReportGet operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreExamSourceExamsExamIdReportGetRequest
 */
export interface ExamApiApiCoreExamSourceExamsExamIdReportGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreExamSourceExamsExamIdReportGet
     */
    readonly examId: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreExamSourceExamsExamIdReportGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreExamSourceExamsGet operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreExamSourceExamsGetRequest
 */
export interface ExamApiApiCoreExamSourceExamsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreExamSourceExamsGet
     */
    readonly sourceId?: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreExamSourceExamsGet
     */
    readonly examId?: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreExamSourceExamsGet
     */
    readonly patientName?: string

    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreExamSourceExamsGet
     */
    readonly categoryId?: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreExamSourceExamsGet
     */
    readonly specialityId?: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreExamSourceExamsGet
     */
    readonly statusId?: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreExamSourceExamsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreExamSourceExamsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreExamSourceExamsGet
     */
    readonly examStartDate?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreExamSourceExamsGet
     */
    readonly examEndDate?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreExamSourceExamsGet
     */
    readonly orderColumn?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreExamSourceExamsGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {boolean}
     * @memberof ExamApiApiCoreExamSourceExamsGet
     */
    readonly printed?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ExamApiApiCoreExamSourceExamsGet
     */
    readonly urgent?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ExamApiApiCoreExamSourceExamsGet
     */
    readonly urgencyAlert?: boolean

    /**
     * 
     * @type {Array<Notification>}
     * @memberof ExamApiApiCoreExamSourceExamsGet
     */
    readonly notifications?: Array<Notification>

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreExamSourceExamsGet
     */
    readonly cpf?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreExamSourceExamsGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreExamSourceExamsObservationPost operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreExamSourceExamsObservationPostRequest
 */
export interface ExamApiApiCoreExamSourceExamsObservationPostRequest {
    /**
     * 
     * @type {InlineObject7}
     * @memberof ExamApiApiCoreExamSourceExamsObservationPost
     */
    readonly inlineObject7: InlineObject7

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreExamSourceExamsObservationPost
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreExamSourceExamsReportsGet operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreExamSourceExamsReportsGetRequest
 */
export interface ExamApiApiCoreExamSourceExamsReportsGetRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof ExamApiApiCoreExamSourceExamsReportsGet
     */
    readonly examsId?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreExamSourceExamsReportsGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreExamSourceExamsSetUrgentExamPut operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreExamSourceExamsSetUrgentExamPutRequest
 */
export interface ExamApiApiCoreExamSourceExamsSetUrgentExamPutRequest {
    /**
     * 
     * @type {InlineObject2}
     * @memberof ExamApiApiCoreExamSourceExamsSetUrgentExamPut
     */
    readonly inlineObject2: InlineObject2
}

/**
 * Request parameters for apiCoreExamSourceExamsStatusGet operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreExamSourceExamsStatusGetRequest
 */
export interface ExamApiApiCoreExamSourceExamsStatusGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreExamSourceExamsStatusGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreExamsInformationGet operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreExamsInformationGetRequest
 */
export interface ExamApiApiCoreExamsInformationGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreExamsInformationGet
     */
    readonly examId?: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreExamsInformationGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreExamsInformationPost operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreExamsInformationPostRequest
 */
export interface ExamApiApiCoreExamsInformationPostRequest {
    /**
     * 
     * @type {InlineObject10}
     * @memberof ExamApiApiCoreExamsInformationPost
     */
    readonly inlineObject10: InlineObject10

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreExamsInformationPost
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreExamsSendReviewPost operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreExamsSendReviewPostRequest
 */
export interface ExamApiApiCoreExamsSendReviewPostRequest {
    /**
     * 
     * @type {InlineObject5}
     * @memberof ExamApiApiCoreExamsSendReviewPost
     */
    readonly inlineObject5: InlineObject5

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreExamsSendReviewPost
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCorePhysicianExamsLaudarInsertMedicalReportPost operation in ExamApi.
 * @export
 * @interface ExamApiApiCorePhysicianExamsLaudarInsertMedicalReportPostRequest
 */
export interface ExamApiApiCorePhysicianExamsLaudarInsertMedicalReportPostRequest {
    /**
     * 
     * @type {InlineObject4}
     * @memberof ExamApiApiCorePhysicianExamsLaudarInsertMedicalReportPost
     */
    readonly inlineObject4: InlineObject4

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCorePhysicianExamsLaudarInsertMedicalReportPost
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCorePhysicianExamsQueueGetItemGet operation in ExamApi.
 * @export
 * @interface ExamApiApiCorePhysicianExamsQueueGetItemGetRequest
 */
export interface ExamApiApiCorePhysicianExamsQueueGetItemGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCorePhysicianExamsQueueGetItemGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCorePhysicianExamsQueueHasNextGet operation in ExamApi.
 * @export
 * @interface ExamApiApiCorePhysicianExamsQueueHasNextGetRequest
 */
export interface ExamApiApiCorePhysicianExamsQueueHasNextGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCorePhysicianExamsQueueHasNextGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCorePhysicianExamsReasonsToRepeatExamBySpecialityIdGet operation in ExamApi.
 * @export
 * @interface ExamApiApiCorePhysicianExamsReasonsToRepeatExamBySpecialityIdGetRequest
 */
export interface ExamApiApiCorePhysicianExamsReasonsToRepeatExamBySpecialityIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCorePhysicianExamsReasonsToRepeatExamBySpecialityIdGet
     */
    readonly specialityId?: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCorePhysicianExamsReasonsToRepeatExamBySpecialityIdGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCorePhysicianExamsRepeatExamPut operation in ExamApi.
 * @export
 * @interface ExamApiApiCorePhysicianExamsRepeatExamPutRequest
 */
export interface ExamApiApiCorePhysicianExamsRepeatExamPutRequest {
    /**
     * 
     * @type {InlineObject6}
     * @memberof ExamApiApiCorePhysicianExamsRepeatExamPut
     */
    readonly inlineObject6: InlineObject6

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCorePhysicianExamsRepeatExamPut
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCorePhysicianExamsUnassignExamMedicPut operation in ExamApi.
 * @export
 * @interface ExamApiApiCorePhysicianExamsUnassignExamMedicPutRequest
 */
export interface ExamApiApiCorePhysicianExamsUnassignExamMedicPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCorePhysicianExamsUnassignExamMedicPut
     */
    readonly xAuth?: string

    /**
     * 
     * @type {ExamUpdateViewModel}
     * @memberof ExamApiApiCorePhysicianExamsUnassignExamMedicPut
     */
    readonly examUpdateViewModel?: ExamUpdateViewModel
}

/**
 * Request parameters for apiCoreUrgencyAlertGetAllNoCheckedAlertsGet operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreUrgencyAlertGetAllNoCheckedAlertsGetRequest
 */
export interface ExamApiApiCoreUrgencyAlertGetAllNoCheckedAlertsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreUrgencyAlertGetAllNoCheckedAlertsGet
     */
    readonly sourceId?: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreUrgencyAlertGetAllNoCheckedAlertsGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreUrgencyAlertGetUrgencyAlertsForAdminGet operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreUrgencyAlertGetUrgencyAlertsForAdminGetRequest
 */
export interface ExamApiApiCoreUrgencyAlertGetUrgencyAlertsForAdminGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiApiCoreUrgencyAlertGetUrgencyAlertsForAdminGet
     */
    readonly examId?: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreUrgencyAlertGetUrgencyAlertsForAdminGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreUrgencyAlertPostIKnowPut operation in ExamApi.
 * @export
 * @interface ExamApiApiCoreUrgencyAlertPostIKnowPutRequest
 */
export interface ExamApiApiCoreUrgencyAlertPostIKnowPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamApiApiCoreUrgencyAlertPostIKnowPut
     */
    readonly xAuth?: string

    /**
     * 
     * @type {InlineObject20}
     * @memberof ExamApiApiCoreUrgencyAlertPostIKnowPut
     */
    readonly inlineObject20?: InlineObject20
}

/**
 * Request parameters for apiPtmsyncPreOrderGet operation in ExamApi.
 * @export
 * @interface ExamApiApiPtmsyncPreOrderGetRequest
 */
export interface ExamApiApiPtmsyncPreOrderGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiApiPtmsyncPreOrderGet
     */
    readonly sourceId?: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiApiPtmsyncPreOrderGet
     */
    readonly examId?: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiPtmsyncPreOrderGet
     */
    readonly patientName?: string

    /**
     * 
     * @type {number}
     * @memberof ExamApiApiPtmsyncPreOrderGet
     */
    readonly categoryId?: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiApiPtmsyncPreOrderGet
     */
    readonly specialityId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof ExamApiApiPtmsyncPreOrderGet
     */
    readonly statusId?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof ExamApiApiPtmsyncPreOrderGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiApiPtmsyncPreOrderGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiPtmsyncPreOrderGet
     */
    readonly examStartDate?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiPtmsyncPreOrderGet
     */
    readonly examEndDate?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiPtmsyncPreOrderGet
     */
    readonly orderColumn?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiPtmsyncPreOrderGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {boolean}
     * @memberof ExamApiApiPtmsyncPreOrderGet
     */
    readonly printed?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ExamApiApiPtmsyncPreOrderGet
     */
    readonly urgent?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ExamApiApiPtmsyncPreOrderGet
     */
    readonly urgencyAlert?: boolean

    /**
     * 
     * @type {Array<Notification>}
     * @memberof ExamApiApiPtmsyncPreOrderGet
     */
    readonly notifications?: Array<Notification>

    /**
     * 
     * @type {string}
     * @memberof ExamApiApiPtmsyncPreOrderGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for examCancelDuplicatesPost operation in ExamApi.
 * @export
 * @interface ExamApiExamCancelDuplicatesPostRequest
 */
export interface ExamApiExamCancelDuplicatesPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamApiExamCancelDuplicatesPost
     */
    readonly xAuth?: string

    /**
     * 
     * @type {CancelDuplicatesRequestViewModel}
     * @memberof ExamApiExamCancelDuplicatesPost
     */
    readonly cancelDuplicatesRequestViewModel?: CancelDuplicatesRequestViewModel
}

/**
 * Request parameters for examDuplicatesGet operation in ExamApi.
 * @export
 * @interface ExamApiExamDuplicatesGetRequest
 */
export interface ExamApiExamDuplicatesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamApiExamDuplicatesGet
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof ExamApiExamDuplicatesGet
     */
    readonly month?: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiExamDuplicatesGet
     */
    readonly year?: number

    /**
     * 
     * @type {Array<Notification>}
     * @memberof ExamApiExamDuplicatesGet
     */
    readonly notifications?: Array<Notification>

    /**
     * 
     * @type {string}
     * @memberof ExamApiExamDuplicatesGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for examRepeatTimeoutPost operation in ExamApi.
 * @export
 * @interface ExamApiExamRepeatTimeoutPostRequest
 */
export interface ExamApiExamRepeatTimeoutPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamApiExamRepeatTimeoutPost
     */
    readonly xAuth?: string
}

/**
 * Request parameters for examSimpleListGet operation in ExamApi.
 * @export
 * @interface ExamApiExamSimpleListGetRequest
 */
export interface ExamApiExamSimpleListGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiExamSimpleListGet
     */
    readonly category?: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiExamSimpleListGet
     */
    readonly status?: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiExamSimpleListGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiExamSimpleListGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {Array<Notification>}
     * @memberof ExamApiExamSimpleListGet
     */
    readonly notifications?: Array<Notification>

    /**
     * 
     * @type {string}
     * @memberof ExamApiExamSimpleListGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for examSourceExamsSetUrgentExamPut operation in ExamApi.
 * @export
 * @interface ExamApiExamSourceExamsSetUrgentExamPutRequest
 */
export interface ExamApiExamSourceExamsSetUrgentExamPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamApiExamSourceExamsSetUrgentExamPut
     */
    readonly xAuth?: string

    /**
     * 
     * @type {ExamUpdateViewModel}
     * @memberof ExamApiExamSourceExamsSetUrgentExamPut
     */
    readonly examUpdateViewModel?: ExamUpdateViewModel
}

/**
 * Request parameters for examUpdatedExamsGet operation in ExamApi.
 * @export
 * @interface ExamApiExamUpdatedExamsGetRequest
 */
export interface ExamApiExamUpdatedExamsGetRequest {
    /**
     * 
     * @type {Array<Notification>}
     * @memberof ExamApiExamUpdatedExamsGet
     */
    readonly notifications?: Array<Notification>

    /**
     * 
     * @type {string}
     * @memberof ExamApiExamUpdatedExamsGet
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiExamUpdatedExamsGet
     */
    readonly endDate?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiExamUpdatedExamsGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for examsCountersGet operation in ExamApi.
 * @export
 * @interface ExamApiExamsCountersGetRequest
 */
export interface ExamApiExamsCountersGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamApiExamsCountersGet
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiExamsCountersGet
     */
    readonly endDate?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiExamsCountersGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for examsQueuePopulateGet operation in ExamApi.
 * @export
 * @interface ExamApiExamsQueuePopulateGetRequest
 */
export interface ExamApiExamsQueuePopulateGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamApiExamsQueuePopulateGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for examsSpecialityPut operation in ExamApi.
 * @export
 * @interface ExamApiExamsSpecialityPutRequest
 */
export interface ExamApiExamsSpecialityPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamApiExamsSpecialityPut
     */
    readonly xAuth?: string

    /**
     * 
     * @type {ExamSpecialityChangeViewModel}
     * @memberof ExamApiExamsSpecialityPut
     */
    readonly examSpecialityChangeViewModel?: ExamSpecialityChangeViewModel
}

/**
 * Request parameters for physicianExamsExamIdExamFileGet operation in ExamApi.
 * @export
 * @interface ExamApiPhysicianExamsExamIdExamFileGetRequest
 */
export interface ExamApiPhysicianExamsExamIdExamFileGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiPhysicianExamsExamIdExamFileGet
     */
    readonly examId: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiPhysicianExamsExamIdExamFileGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for physicianExamsReportedGet operation in ExamApi.
 * @export
 * @interface ExamApiPhysicianExamsReportedGetRequest
 */
export interface ExamApiPhysicianExamsReportedGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamApiPhysicianExamsReportedGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for physicianExamsUpdateDateLasUpdatetExamPut operation in ExamApi.
 * @export
 * @interface ExamApiPhysicianExamsUpdateDateLasUpdatetExamPutRequest
 */
export interface ExamApiPhysicianExamsUpdateDateLasUpdatetExamPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamApiPhysicianExamsUpdateDateLasUpdatetExamPut
     */
    readonly xAuth?: string

    /**
     * 
     * @type {ExamUpdateViewModel}
     * @memberof ExamApiPhysicianExamsUpdateDateLasUpdatetExamPut
     */
    readonly examUpdateViewModel?: ExamUpdateViewModel
}

/**
 * ExamApi - object-oriented interface
 * @export
 * @class ExamApi
 * @extends {BaseAPI}
 */
export class ExamApi extends BaseAPI {
    /**
     * 
     * @param {ExamApiAdministratorExamConfirmDivergencePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public administratorExamConfirmDivergencePost(requestParameters: ExamApiAdministratorExamConfirmDivergencePostRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).administratorExamConfirmDivergencePost(requestParameters.xAuth, requestParameters.postDivergenceViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiAdministratorExamDivergenceGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public administratorExamDivergenceGet(requestParameters: ExamApiAdministratorExamDivergenceGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).administratorExamDivergenceGet(requestParameters.examId, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiAdministratorExamDivergenceResolvePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public administratorExamDivergenceResolvePost(requestParameters: ExamApiAdministratorExamDivergenceResolvePostRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).administratorExamDivergenceResolvePost(requestParameters.xAuth, requestParameters.cdpDivergenceResolveViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiAdministratorExamDivergenceUpdatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public administratorExamDivergenceUpdatePost(requestParameters: ExamApiAdministratorExamDivergenceUpdatePostRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).administratorExamDivergenceUpdatePost(requestParameters.xAuth, requestParameters.postDivergenceViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiAdministratorExamPatientAgeFromExamIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public administratorExamPatientAgeFromExamIdGet(requestParameters: ExamApiAdministratorExamPatientAgeFromExamIdGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).administratorExamPatientAgeFromExamIdGet(requestParameters.examId, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiAdministratorExamsAIStudyGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public administratorExamsAIStudyGet(requestParameters: ExamApiAdministratorExamsAIStudyGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).administratorExamsAIStudyGet(requestParameters.examId, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiAdministratorExamsAIStudyPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public administratorExamsAIStudyPost(requestParameters: ExamApiAdministratorExamsAIStudyPostRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).administratorExamsAIStudyPost(requestParameters.xAuth, requestParameters.examAIStudyViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiAdministratorExamsComparisonGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public administratorExamsComparisonGet(requestParameters: ExamApiAdministratorExamsComparisonGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).administratorExamsComparisonGet(requestParameters.examId, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiAdministratorExamsEvaluateAilaResultPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public administratorExamsEvaluateAilaResultPut(requestParameters: ExamApiAdministratorExamsEvaluateAilaResultPutRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).administratorExamsEvaluateAilaResultPut(requestParameters.xAuth, requestParameters.ailaEvaluateExamComparisonViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiAdministratorExamsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public administratorExamsGet(requestParameters: ExamApiAdministratorExamsGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).administratorExamsGet(requestParameters.examId, requestParameters.patientName, requestParameters.categoryId, requestParameters.specialityId, requestParameters.statusId, requestParameters.page, requestParameters.pageSize, requestParameters.examStartDate, requestParameters.examEndDate, requestParameters.orderColumn, requestParameters.orderBy, requestParameters.lastReportStartDate, requestParameters.lastReportEndDate, requestParameters.printed, requestParameters.sourceId, requestParameters.physicianId, requestParameters.internalSystem, requestParameters.urgencyAlert, requestParameters.divergence, requestParameters.notifications, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiAdministratorExamsObservationPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public administratorExamsObservationPost(requestParameters: ExamApiAdministratorExamsObservationPostRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).administratorExamsObservationPost(requestParameters.xAuth, requestParameters.adminPostObservationViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiAdministratorExamsPullPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public administratorExamsPullPost(requestParameters: ExamApiAdministratorExamsPullPostRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).administratorExamsPullPost(requestParameters.xAuth, requestParameters.adminPullViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiAdministratorExamsReleaseReportPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public administratorExamsReleaseReportPost(requestParameters: ExamApiAdministratorExamsReleaseReportPostRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).administratorExamsReleaseReportPost(requestParameters.xAuth, requestParameters.releaseReportViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiAdministratorExamsRetificationReasonsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public administratorExamsRetificationReasonsGet(requestParameters: ExamApiAdministratorExamsRetificationReasonsGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).administratorExamsRetificationReasonsGet(requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiAnalystExamsQueueHasNextGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public analystExamsQueueHasNextGet(requestParameters: ExamApiAnalystExamsQueueHasNextGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).analystExamsQueueHasNextGet(requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiAtenaAdminUserGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiAtenaAdminUserGet(requestParameters: ExamApiApiAtenaAdminUserGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiAtenaAdminUserGet(requestParameters.page, requestParameters.pageSize, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiAtenaAdminUserPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiAtenaAdminUserPut(requestParameters: ExamApiApiAtenaAdminUserPutRequest, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiAtenaAdminUserPut(requestParameters.inlineObject8, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreAdministratorExamAssignExamToPhysicianPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreAdministratorExamAssignExamToPhysicianPost(requestParameters: ExamApiApiCoreAdministratorExamAssignExamToPhysicianPostRequest, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreAdministratorExamAssignExamToPhysicianPost(requestParameters.inlineObject14, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreAdministratorExamReplaceReportDocumentPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreAdministratorExamReplaceReportDocumentPost(requestParameters: ExamApiApiCoreAdministratorExamReplaceReportDocumentPostRequest, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreAdministratorExamReplaceReportDocumentPost(requestParameters.inlineObject11, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreAdministratorExamSignedUrlGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreAdministratorExamSignedUrlGet(requestParameters: ExamApiApiCoreAdministratorExamSignedUrlGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreAdministratorExamSignedUrlGet(requestParameters.examId, requestParameters.examFileId, requestParameters.md5, requestParameters.extension, requestParameters.fileType, requestParameters.fileName, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreAdministratorExamsCancelPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreAdministratorExamsCancelPut(requestParameters: ExamApiApiCoreAdministratorExamsCancelPutRequest, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreAdministratorExamsCancelPut(requestParameters.inlineObject15, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreAdministratorExamsChangePhysicianPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreAdministratorExamsChangePhysicianPut(requestParameters: ExamApiApiCoreAdministratorExamsChangePhysicianPutRequest, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreAdministratorExamsChangePhysicianPut(requestParameters.inlineObject13, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreAdministratorExamsDetailsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreAdministratorExamsDetailsGet(requestParameters: ExamApiApiCoreAdministratorExamsDetailsGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreAdministratorExamsDetailsGet(requestParameters.examId, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreAdministratorExamsFileGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreAdministratorExamsFileGet(requestParameters: ExamApiApiCoreAdministratorExamsFileGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreAdministratorExamsFileGet(requestParameters.examFileId, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreAdministratorExamsPhysiciansForExamGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreAdministratorExamsPhysiciansForExamGet(requestParameters: ExamApiApiCoreAdministratorExamsPhysiciansForExamGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreAdministratorExamsPhysiciansForExamGet(requestParameters.examId, requestParameters.physicianName, requestParameters.page, requestParameters.pageSize, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreAdministratorExamsRetificationPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreAdministratorExamsRetificationPost(requestParameters: ExamApiApiCoreAdministratorExamsRetificationPostRequest, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreAdministratorExamsRetificationPost(requestParameters.inlineObject12, requestParameters.examId, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreAdministratorExamsSetSuccessUploadStatusPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreAdministratorExamsSetSuccessUploadStatusPut(requestParameters: ExamApiApiCoreAdministratorExamsSetSuccessUploadStatusPutRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreAdministratorExamsSetSuccessUploadStatusPut(requestParameters.examId, requestParameters.examFileId, requestParameters.md5, requestParameters.extension, requestParameters.fileType, requestParameters.fileName, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreExamAssociatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreExamAssociatePost(requestParameters: ExamApiApiCoreExamAssociatePostRequest, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreExamAssociatePost(requestParameters.inlineObject16, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreExamGetAssociationCandidatesExamIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreExamGetAssociationCandidatesExamIdGet(requestParameters: ExamApiApiCoreExamGetAssociationCandidatesExamIdGetRequest, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreExamGetAssociationCandidatesExamIdGet(requestParameters.examId, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreExamSourceCategoryGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreExamSourceCategoryGet(requestParameters: ExamApiApiCoreExamSourceCategoryGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreExamSourceCategoryGet(requestParameters.specialityId, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreExamSourceExamsByDocumentGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreExamSourceExamsByDocumentGet(requestParameters: ExamApiApiCoreExamSourceExamsByDocumentGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreExamSourceExamsByDocumentGet(requestParameters.sourceId, requestParameters.cpf, requestParameters.page, requestParameters.pageSize, requestParameters.notifications, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreExamSourceExamsExamIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreExamSourceExamsExamIdPut(requestParameters: ExamApiApiCoreExamSourceExamsExamIdPutRequest, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreExamSourceExamsExamIdPut(requestParameters.examId, requestParameters.inlineObject3, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreExamSourceExamsExamIdReportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreExamSourceExamsExamIdReportGet(requestParameters: ExamApiApiCoreExamSourceExamsExamIdReportGetRequest, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreExamSourceExamsExamIdReportGet(requestParameters.examId, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreExamSourceExamsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreExamSourceExamsGet(requestParameters: ExamApiApiCoreExamSourceExamsGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreExamSourceExamsGet(requestParameters.sourceId, requestParameters.examId, requestParameters.patientName, requestParameters.categoryId, requestParameters.specialityId, requestParameters.statusId, requestParameters.page, requestParameters.pageSize, requestParameters.examStartDate, requestParameters.examEndDate, requestParameters.orderColumn, requestParameters.orderBy, requestParameters.printed, requestParameters.urgent, requestParameters.urgencyAlert, requestParameters.notifications, requestParameters.cpf, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreExamSourceExamsObservationPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreExamSourceExamsObservationPost(requestParameters: ExamApiApiCoreExamSourceExamsObservationPostRequest, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreExamSourceExamsObservationPost(requestParameters.inlineObject7, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreExamSourceExamsReportsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreExamSourceExamsReportsGet(requestParameters: ExamApiApiCoreExamSourceExamsReportsGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreExamSourceExamsReportsGet(requestParameters.examsId, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreExamSourceExamsSetUrgentExamPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreExamSourceExamsSetUrgentExamPut(requestParameters: ExamApiApiCoreExamSourceExamsSetUrgentExamPutRequest, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreExamSourceExamsSetUrgentExamPut(requestParameters.inlineObject2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreExamSourceExamsStatusGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreExamSourceExamsStatusGet(requestParameters: ExamApiApiCoreExamSourceExamsStatusGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreExamSourceExamsStatusGet(requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreExamsInformationGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreExamsInformationGet(requestParameters: ExamApiApiCoreExamsInformationGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreExamsInformationGet(requestParameters.examId, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreExamsInformationPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreExamsInformationPost(requestParameters: ExamApiApiCoreExamsInformationPostRequest, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreExamsInformationPost(requestParameters.inlineObject10, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreExamsSendReviewPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreExamsSendReviewPost(requestParameters: ExamApiApiCoreExamsSendReviewPostRequest, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreExamsSendReviewPost(requestParameters.inlineObject5, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCorePhysicianExamsLaudarInsertMedicalReportPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCorePhysicianExamsLaudarInsertMedicalReportPost(requestParameters: ExamApiApiCorePhysicianExamsLaudarInsertMedicalReportPostRequest, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCorePhysicianExamsLaudarInsertMedicalReportPost(requestParameters.inlineObject4, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCorePhysicianExamsQueueGetItemGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCorePhysicianExamsQueueGetItemGet(requestParameters: ExamApiApiCorePhysicianExamsQueueGetItemGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCorePhysicianExamsQueueGetItemGet(requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCorePhysicianExamsQueueHasNextGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCorePhysicianExamsQueueHasNextGet(requestParameters: ExamApiApiCorePhysicianExamsQueueHasNextGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCorePhysicianExamsQueueHasNextGet(requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCorePhysicianExamsReasonsToRepeatExamBySpecialityIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCorePhysicianExamsReasonsToRepeatExamBySpecialityIdGet(requestParameters: ExamApiApiCorePhysicianExamsReasonsToRepeatExamBySpecialityIdGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCorePhysicianExamsReasonsToRepeatExamBySpecialityIdGet(requestParameters.specialityId, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCorePhysicianExamsRepeatExamPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCorePhysicianExamsRepeatExamPut(requestParameters: ExamApiApiCorePhysicianExamsRepeatExamPutRequest, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCorePhysicianExamsRepeatExamPut(requestParameters.inlineObject6, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCorePhysicianExamsUnassignExamMedicPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCorePhysicianExamsUnassignExamMedicPut(requestParameters: ExamApiApiCorePhysicianExamsUnassignExamMedicPutRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCorePhysicianExamsUnassignExamMedicPut(requestParameters.xAuth, requestParameters.examUpdateViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreUrgencyAlertGetAllNoCheckedAlertsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreUrgencyAlertGetAllNoCheckedAlertsGet(requestParameters: ExamApiApiCoreUrgencyAlertGetAllNoCheckedAlertsGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreUrgencyAlertGetAllNoCheckedAlertsGet(requestParameters.sourceId, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreUrgencyAlertGetUrgencyAlertsForAdminGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreUrgencyAlertGetUrgencyAlertsForAdminGet(requestParameters: ExamApiApiCoreUrgencyAlertGetUrgencyAlertsForAdminGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreUrgencyAlertGetUrgencyAlertsForAdminGet(requestParameters.examId, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiCoreUrgencyAlertPostIKnowPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiCoreUrgencyAlertPostIKnowPut(requestParameters: ExamApiApiCoreUrgencyAlertPostIKnowPutRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiCoreUrgencyAlertPostIKnowPut(requestParameters.xAuth, requestParameters.inlineObject20, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiApiPtmsyncPreOrderGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public apiPtmsyncPreOrderGet(requestParameters: ExamApiApiPtmsyncPreOrderGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).apiPtmsyncPreOrderGet(requestParameters.sourceId, requestParameters.examId, requestParameters.patientName, requestParameters.categoryId, requestParameters.specialityId, requestParameters.statusId, requestParameters.page, requestParameters.pageSize, requestParameters.examStartDate, requestParameters.examEndDate, requestParameters.orderColumn, requestParameters.orderBy, requestParameters.printed, requestParameters.urgent, requestParameters.urgencyAlert, requestParameters.notifications, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiExamCancelDuplicatesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public examCancelDuplicatesPost(requestParameters: ExamApiExamCancelDuplicatesPostRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).examCancelDuplicatesPost(requestParameters.xAuth, requestParameters.cancelDuplicatesRequestViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiExamDuplicatesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public examDuplicatesGet(requestParameters: ExamApiExamDuplicatesGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).examDuplicatesGet(requestParameters.name, requestParameters.month, requestParameters.year, requestParameters.notifications, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiExamRepeatTimeoutPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public examRepeatTimeoutPost(requestParameters: ExamApiExamRepeatTimeoutPostRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).examRepeatTimeoutPost(requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiExamSimpleListGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public examSimpleListGet(requestParameters: ExamApiExamSimpleListGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).examSimpleListGet(requestParameters.category, requestParameters.status, requestParameters.page, requestParameters.pageSize, requestParameters.notifications, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiExamSourceExamsSetUrgentExamPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public examSourceExamsSetUrgentExamPut(requestParameters: ExamApiExamSourceExamsSetUrgentExamPutRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).examSourceExamsSetUrgentExamPut(requestParameters.xAuth, requestParameters.examUpdateViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiExamUpdatedExamsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public examUpdatedExamsGet(requestParameters: ExamApiExamUpdatedExamsGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).examUpdatedExamsGet(requestParameters.notifications, requestParameters.startDate, requestParameters.endDate, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiExamsCountersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public examsCountersGet(requestParameters: ExamApiExamsCountersGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).examsCountersGet(requestParameters.startDate, requestParameters.endDate, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiExamsQueuePopulateGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public examsQueuePopulateGet(requestParameters: ExamApiExamsQueuePopulateGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).examsQueuePopulateGet(requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiExamsSpecialityPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public examsSpecialityPut(requestParameters: ExamApiExamsSpecialityPutRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).examsSpecialityPut(requestParameters.xAuth, requestParameters.examSpecialityChangeViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiPhysicianExamsExamIdExamFileGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public physicianExamsExamIdExamFileGet(requestParameters: ExamApiPhysicianExamsExamIdExamFileGetRequest, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).physicianExamsExamIdExamFileGet(requestParameters.examId, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiPhysicianExamsReportedGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public physicianExamsReportedGet(requestParameters: ExamApiPhysicianExamsReportedGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).physicianExamsReportedGet(requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiPhysicianExamsUpdateDateLasUpdatetExamPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public physicianExamsUpdateDateLasUpdatetExamPut(requestParameters: ExamApiPhysicianExamsUpdateDateLasUpdatetExamPutRequest = {}, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).physicianExamsUpdateDateLasUpdatetExamPut(requestParameters.xAuth, requestParameters.examUpdateViewModel, options).then((request) => request(this.axios, this.basePath));
    }
}
